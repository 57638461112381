.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  opacity: 0;
  animation: overlayOpen 0.5s ease-in-out forwards;
  -webkit-backdrop-filter: blur(10px);

  &.hide{
    animation: overlayClose 0.5s ease-in-out forwards;
  }
}

.reuse_order {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(100%, -50%);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.498);
  color: rgb(26, 26, 26);
  border-radius: 15px;
  //box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  gap: 10px;
  z-index: 110;
  transition: all 0.5s ease-in-out;
  animation: slideInFromLeft 0.5s ease-in-out forwards 0.5s;

  .titre{
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
  &.hide{
    animation: slideOutToLeft 0.5s ease-in-out forwards;
  }
  .details{
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    opacity: 0.9;
  }
  .content{
    max-height: 50vh;
    width: 100%;
    overflow-y: auto;
  }

    .reuse_order_closeArrow{
      position: absolute;
      top: 5px;
      width: 30px;
      height: 30px;
      right: 5px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

  .reuse_order_buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1em;

    .no{
      padding: 16px 20px;
      border-radius: 10px;
      background: #f5f5f5;
      border: none;
      width: 100%;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      &:active{
        background: #e5e5e5;
      }
      &.load{
        pointer-events: none;
        opacity: 0.2;
      }
    
    }
    .yes{
      width: 100%;
      height: calc(100% - 8px);
      padding: 10px 20px;

      &.load{
        animation: load 1s infinite;
      }
    }
  }
  .cart_list{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  
    padding: 1em;
    border-radius: 10px;
    background: #e7eae7;
    border: none;
    align-items: flex-start;
    justify-content: center;
    min-height: max-content;
    margin: 1em 0;

      .product_box{
      display: flex;
      justify-content: flex-start;
      gap: 1em;
      align-items: center;
      width: 100%;

  
      .price_box{
        display: flex;
        flex-direction: column;
        gap: 0.2em;
      }
      .name_price{
        display: flex;
        flex-direction: column;
        gap: 0.2em;
        .product_name{
          font-size: 1.2em;
          font-weight: 600;
        }
        .product_price{
          font-size: 1em;
          font-weight: 600;
          font-size: 1.3em;
        }
      }

    .image_box{
      width: 50px;
      height: 50px;
      border-radius: 10px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  }
 
}

@keyframes slideInFromLeft {
  0% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes load{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}
@keyframes slideOutToLeft {
  0% {
    transform: translate(-50%, -50%);
  }
  80% {
    transform: translate(150%, -50%);
  }
  100% {
    display: none;
  }
}

@keyframes overlayOpen{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes overlayClose{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}