/* GENERAL */
.productscreen{
    position: absolute;
    /* min-height: 100vh; */
    height: 100%;
    min-width: 100%;
    background-color: #FFFEF1;
    /* background-size: auto; */
    background-size: cover;
    background-repeat:no-repeat;
    overflow: auto;
}
.productscreen1{

}
.productscreen2{
    position: absolute;
    top: 500px;
    /* bottom: 0; */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 50%;
    background: linear-gradient(96.63deg, #FF627F 13.87%, #FFBA88 87.26%);;
    border-radius: 25px 25px 0px 0px;
    padding-bottom: 115px;
    color: white;
} 

/* PRODUCT 1 */

#imglogo{
    /* width: 400px;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);
    top: 50px; */
    display: none;
}
#imgproduct{
    display: flex;
    position: relative;
    justify-content: center;
    left: 50%;
    transform: translate(-50%);
    /* border: 7px solid white; */
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.4);
    top: 45px;
}
.productname{
    position: absolute;
    min-width: 100%;
    min-height: 10%;
    justify-content: center;
    top: 335px;
    font-family: 'Nunito';
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15px;
    display: grid;
    align-items: center;
    text-align: center;
    color: #000000;
    line-height: 60px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#price_product{
    font-family: 'poetsen_oneregular';
    font-style: normal;
    font-weight: normal;
}

/* PRODUCT2 */

.product{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    min-height: 100%;
    min-width: 100%;
}
.infoproduct{
    min-width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    display: block;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 2.5rem;
    align-items: center;
   
}
.infoproduct_description{
    height: 150px;
    color: #000000;
    background-color: white;
    border-radius: 10px;
    padding: 1rem 2rem 1rem 2rem;
    font-size: 14px;
    overflow: auto;
    position: relative;
    top: 20px;
}
.infoproduct_size_text{
    font-weight: 700;
    margin-bottom: 15px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}
#description_img{
    width: 20px;
    height: 20px;
    margin-right: 1rem;
}
.infoproduct2_details{
    padding-bottom: 1rem;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}
.infoproduct2{
    padding-top: 2rem;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

}
/* FOOTER */
.footerproduct{
    display: flex;
    align-items: center;
    justify-content: center;
}
.card{
    /* background: linear-gradient(106deg, #755FE2 5.13%, #F5B2B5 128.93%); */
    background-color: white;
    border-radius: 77px;
    box-shadow: 0px 4px 30px rgba(0,0,0,0.29);
    width: 95%;
}
.addtocart{
    display: flex;
    align-content: center;
    min-width: 100%;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}
.addtocart_button{
    width: 100%;
    border: none;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 10px;

}

.shoppingcart{
    display: flex;
    align-content: center;
    justify-content: flex-end;
}
.addtocart_text{
    display: flex;
    align-content: center;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

/* @media screen and (min-height: 870px) {
    .productscreen2{
        height: 45%;
    }

}
@media screen and (min-height: 905px) {
    .productscreen2{
        height: 50%;
    }

}
@media screen and (min-height: 956px) {
    .productscreen2{
        height: 55%;
    }

} */
@media screen and (min-height: 600px) {
    .productscreen2{
        height: 45%;
    }

}
@media screen and (min-height: 700px) {
    .productscreen2{
        height: 40%;
    }

}
@media screen and (min-height: 800px) {
    .productscreen2{
        height: 45%;
    }
}
@media screen and (min-height: 900px) {
    .productscreen2{
        height: 50%;
    }
}