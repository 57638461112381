.c-leaf {
	position: relative;
	overflow: hidden;
	/* width: 203px;
	height: 133px; */
	margin: 0 auto;
	background: #eee;
	/* transform: scale(1); */
}

.c-leaf__icon {
	position: absolute;
	top: -1px;
	left: -1px;
	z-index: 1;
	/* width: 180px;
	height: 170px; */
	fill: #fffdf1;
	stroke: none;
}

.form_name {
	margin-top: 1.5em;
	background: transparent;
	margin-bottom: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;

}
.form_name input {
	width: 100%;
	height: 100%;
	border: none;
	border-bottom: 1px solid #000000;
	padding: 0.5rem;
	font-family: 'poppinslight';
}
.c-leaf__fill {
	/* width: 177px;
	height: 162px; */
	transform: scale(0.35);
	width: 295px;
	height: 256px;
	background: #71B261;
	-webkit-animation: fillUp 0.5s ease-in-out infinite;
	animation: fillUp 0.5s ease-in-out infinite;
}

@-webkit-keyframes fillUp {
	0% {
		transform: translate(0, 256ppx);
	}

	100% {
		transform: translate(0, -256ppx);
	}
}

@keyframes fillUp {
	0% {
		transform: translate(0, 256px);
	}

	100% {
		transform: translate(0, -256px);
	}
}

.x-reset-container {
	display: flex;
	align-items: center;
	transform: scale(0.35);

}


/****/

 .pageLoader {
    width: 48px;
    height: 48px;
    border: 5px solid #71B261;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }  


	//  .pageLoader {
	// 	width: 48px;
	// 	height: 48px;
	// 	border-radius: 50%;
	// 	position: relative;
	// 	animation: rotate 1s linear infinite
	//   }
	//   .pageLoader::before {
	// 	content: "";
	// 	box-sizing: border-box;
	// 	position: absolute;
	// 	inset: 0px;
	// 	border-radius: 50%;
	// 	border: 7px solid #71B261;
	// 	animation: prixClipFix 2s linear infinite ;
	//   }
  
	  @keyframes rotate {
		100%   {transform: rotate(360deg)}
	  }
  
	  @keyframes prixClipFix {
		  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
		  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
		  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
		  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
		  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
	  }
	   

	//    .pageLoader {
	// 	width: 48px;
	// 	height: 48px;
	// 	border: 5px solid  #71B261;
	// 	border-bottom-color: transparent;
	// 	border-radius: 50%;
	// 	display: inline-block;
	// 	box-sizing: border-box;
	// 	animation: rotation 1s linear infinite;
	// 	}


@media screen and (max-width:400px) {
	.pikopay_logo{
		width: 20%;
	}
}