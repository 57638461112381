.ReloadPage {
    position: fixed;
    left: 50%;
    padding: 12px;
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.498);
    color: rgb(26, 26, 26);
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1110;
    width: 98%; 
    transition: all 0.5s ease-in-out;


    &.top{
        animation: slideFromTop 0.5s ease-in-out 3s forwards;
        transform: translateY(-200%) translateX(-50%);
        top: 5px;
         &.hide{

            animation: slideToTop 0.5s ease-in-out forwards;
        }

    }

    &.bottom{
        animation: slideFromBottom 0.5s ease-in-out 3s forwards;
        bottom: 5px;
        transform: translateY(200%) translateX(-50%);
        &.hide{
            animation: slideToBottom 0.5s ease-in-out forwards;
        }
    }

  
    
    .content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 90%;

      button {
        padding:12px;
        background: #010002c7;
        border: none;
        color: white;
        border-radius: 8px;
        width: 100%;

        &:active {
            background-color: #777;
          }

      }
      span {
        color: rgb(27, 27, 27);
        font-size: 2.2rem;
        font-family: 'geomanistbold';
        font-weight: 600;
        width: 100%;
        text-align: center;
      }
     

      p{
        color: rgba(0, 0, 0, 0.605);
        font-size: 1.1rem;
        font-weight: 400;
      }
    }
    .close{
        width: 25px;
        z-index: 100;
        position: absolute;
        right: 10px;
        top: 10px;
      }

    .cam{
        width: 40px;
        opacity: 0.8;
        z-index: 100;
      }
  }

 
  @keyframes slideFromTop {
    0% {
      transform: translateY(-200%) translateX(-50%);
    }
    100% {
      transform: translateY(0) translateX(-50%);
    }
  }

  @keyframes slideToTop {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    100% {
      transform: translateY(-200%) translateX(-50%);
    }
  }

  @keyframes slideFromBottom {
    0% {
      transform: translateY(200%) translateX(-50%);
    }
    100% {
      transform: translateY(0) translateX(-50%);
    }
  }
  @keyframes slideToBottom {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    100% {
      transform: translateY(200%) translateX(-50%);
    }
  }
  @media screen and (max-height: 568px){
    .ReloadPage p{
        display: none;
    }
  }