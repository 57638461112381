@import './_settings.scss';
@import './_variables.scss';
@import './components/_gridfooter.scss';
@import './components/_gridheader.scss';
@import './components/headerproduct.scss';
@import './components/headercart.scss';
@import './components/card/card.scss';
@import './components/card/footer-cart.scss';
@import './components/_scan.scss';
@import './components/_homescreen.scss';
@import './components/_productscreen.scss';
@import './components/_test.scss';
@import './components/_orderscreen.scss';
@import './components/_successscreen.scss';
@import './components/_checkscreen.scss';
@import './components/_homeLoader.scss';
@import './components/_pageLoader.scss';
@import './components/_successAnimation.scss';
@import './components/_paygreen.scss';
@import './components/_promoscreen.scss';
@import './components/_popuppayment.scss';
@import './components/_recompense.scss';
@import './components/account.scss';
@import './components/email.scss';
@import './components/brand/_brand.scss';
@import './components/account/_pikko-point-header.scss';
@import './components/account/_account-layout.scss';
@import './components/account/_navigation-menu.scss';
@import './components/layout/scan-footer.scss';
@import './components/layout/header.scss';
@import './components/reward/_reward.scss';
@import './components/reward/_reward-convert.scss';
@import './components/reward/_gift-card.scss';
@import './components/ticket/_ticket.scss';
@import './components/button/_button.scss';
@import './components/loader/_loader.scss';
@import './components/pikkobox/_pikkobox.scss';
@import './components/css-loader-processing.scss';
@import './components/gift/_surprise.scss';
@import './components/card/_card.scss';
@import './components/slider/swipper.scss';
@import './components/store_list.scss';
@import './components/welcome_screen.scss';
@import './components/profilbox/profilbox.scss';
@import './components/_stepBar.scss';
@import './components/slider/_userinfo.scss';
@import './components/_giftscreen.scss';
@import './components/_invitationscreen.scss';
@import './components/wallet/_wallet.scss';
@import './components/card/_fidelity_card.scss';
@import './components/fid/fidelity.scss';
@import './components/fid/fidelity-login.scss';
@import './components/fid/user-fidelity-card.scss';
@import './components/popin/popin.scss';
@import './components/tile/company-tile.scss';
@import './components/layout/_notify.scss';
@import './components/payzen/_payzen.scss';
@import './components/payzen/_payzenApplepay.scss';
@import './components/account/_ticket.scss';
@import  './components/tile/Toaster.scss';
@import './components/card/_reuseorder.scss';
a{
  text-decoration: none;
  color:inherit;
}

[data-rsbs-header]{
  padding: 3.5em !important;
  background: transparent !important;
}
[data-rsbs-header]::before{
  width: 100px !important;
  height: 8px !important;
  border-radius: 20px !important;
}
.shadow_ticket{
  box-shadow:0px 5px 30px 0px rgba(39, 39, 39, 0.164);

}
.overflow-auto{
  overflow-y: auto;
}
.text_secondary{
  font-size: small;
  color: #303030b6;
}
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}
.small_img{
  height: 2.5rem;
  width: auto;
}
.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  /* height: 400px; */
  width: 100%;
  /* border-radius: 40px; */
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
button{
  border: none;
  outline: none;

}
li{
  list-style: none;
}

a{
  text-decoration: none;
  color:inherit;
}

[data-rsbs-header]{
  padding: 2em !important;
}
.shadow_ticket{
  box-shadow:0px 5px 30px 0px rgba(39, 39, 39, 0.164);

}
.overflow-auto{
  overflow-y: auto;
}
.text_secondary{
  font-size: small;
  color: #303030b6;
}
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}
.small_img{
  height: 2.5rem;
  width: auto;
}
.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  /* height: 400px; */
  width: 100%;
  /* border-radius: 40px; */
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
button{
  border: none;
  outline: none;

}
li{
  list-style: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
