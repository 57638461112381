@import '../../variables';

.loader-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .loader-custom {
        width: 40px;
        height: 40px;
        border: 5px solid  #71B261;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        &.big {
            width: 60px;
            height: 60px;
        }

        &.small {
            width: 20px;
            height: 20px;
            border-width: 2px;
        }

        &.grey {
            border-color: $gray;
            border-bottom-color: transparent;
        }

        &.yellow {
            border-color: $yellow;
            border-bottom-color: transparent;
        }

        &.black {
            border-color: $black;
            border-bottom-color: transparent;
        }
    }
}

.new_loading_container{
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
    align-items: center;
    width: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(209, 208, 208, 0.437);
    height: 100%;
    font-family: 'geomanistRegular';
  }
  .new_loading_text{
    color: rgb(255, 255, 255);
    font-size: 1.4em;
    display: flex;
    border-radius: 0.5em;
    max-width: 70%;
    gap: 1em;
    align-items: center;
    justify-content: center;
    padding: 1em;
    background: rgba(0, 0, 0, 0.586);
    font-family: 'geomanistRegular';
  }
  svg.svg {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
   }
   
  circle.circle {
    fill: none;
    stroke: hsl(75, 97%, 41%);
    stroke-width: 9;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
   }
   
   @keyframes rotate4 {
    100% {
     transform: rotate(360deg);
    }
   }
   
   @keyframes dash4 {
    0% {
     stroke-dasharray: 1, 200;
     stroke-dashoffset: 0;
    }
   
    50% {
     stroke-dasharray: 90, 200;
     stroke-dashoffset: -35px;
    }
   
    100% {
     stroke-dashoffset: -125px;
    }
   }
   