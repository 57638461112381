.giftscreen_contenaire{
    background:  linear-gradient(140deg, rgb(249, 255, 212) 15%, rgb(255, 249, 225) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40% 2em;
}

.contenaire_gift{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

.contenaire_gift_promo{
    display: flex;
    flex-direction: column;
    position: relative;
   
    transform: rotate(5deg);
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2em;
    border: solid 4px black;
    border-radius: 8em;
    background:linear-gradient(140deg, rgb(229, 255, 57) 15%, rgb(255, 214, 65) 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.384);
padding: 2em;
animation: descenterotation 0.6s linear;
}
.box_gift_blanc{
    width: 100%;
    height: 100%;
    background: #FFF8E5;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 6em;
 
 }
.contenaire_gift_promo span{
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    width: 100%;


}
.box_gift_blanc span{
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    width: 100%;
    padding: 0.2em;

}
.contenaire_gift_promo p,h1{
    margin: 0.5em 0.5em 0.5em 0em ;
    line-height: 18px;
    position: relative;
    text-align: center;
   
}

.giftscreen_contenaire::before{
    content:"";
    position: absolute;
    width: 6px;
    height: 50%;
    z-index: 0;
    background-color: white;
    box-shadow: inset 2px 0px 2px rgba(0, 0, 0, 0.5);
    top: 0;
    animation: descente 1s  linear;
}
.nn_btn{
    border: none;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    outline: none;
    position: absolute;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
}
.Brvo_text{
   position: relative;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin: 1em 0;
   
}
.gift_principal_btn{
    border: none;
    outline: none;
    /* background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(251, 255, 38) 100%); */
    background: #000000;
    color: rgb(255, 255, 255);
    padding: 1em;
    font-weight: 900;
    border-radius: 50px;
    width: 120px;
    
    margin-top: 3em;

    position: absolute;
    bottom: -4em;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.384);

}

.confetti{
    max-width: 640px;
    display: block;
    margin: 0 auto;
    border: 1px solid #ddd;
    user-select: none;
 }
 .confettis{
    z-index: 500 !important;
 }


 .gift_svg_anim{
    animation: gelatine 1s ease-in-out 3;
    -webkit-filter: drop-shadow(-3px 1px 2px #00000071);
filter: drop-shadow(-3px 1px 2px #00000071);

 }
 .instruction_promos{
    font-size: 12px;
    opacity: 0.5;
 }

 @keyframes gelatine {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.9, 1.1); }
    50% { transform: scale(1.1, 0.9); }
    75% { transform: scale(0.95, 1.05); }
  }

  @keyframes descente {
    from { transform: translateY(-300px); }
   
    to { transform: translateY(0px); }
  }
  @keyframes descenterotation {
    from { transform: translateY(-300px) rotate(-25deg); }
   
    to { transform: translateY(0px) rotate(5deg); }
  }