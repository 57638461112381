@import '../../variables';

$padding: 20px;

.pikko-point-header {
    position: relative;
    height: $height-pikko-point-header;
    width: 100%;
    background: $pikko-box-gradient;

    .header-background-img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .second-btn{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-20px);
    }
    .header-content {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: $padding;

        .action-button {
            position: absolute;
            width: 100%;
            padding: 0 $padding;
            display: flex;
            justify-content: space-between;

            img {
                max-height: 40px;
                max-width: 70px;
            }
        }

        .point-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            height: 70%;
        }
    }
}
