.kr-smart-button-wrapper {
  -apple-pay-button-style: black;
  -webkit-appearance: -apple-pay-button !important;
  cursor: pointer;
  height: 50px;
  max-height: 64px;
  min-height: 32px;
}
.kr-smart-button-wrapper .kr-smart-button{
  width: 100%;
  height: 50px;
  opacity: 0;
}
