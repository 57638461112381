.container_invitation_page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: clamp(0.2em, 4vw, 4em);
    padding: 2em;
    margin-top: 1em;
   

            .invitation_title{
                font-size: 28px;
           
                font-weight: 600;
                font-family: 'geomanistbold';
                text-align: center;
                width: 100%;
            }
                .logo_invitation{
            width: 100%;
            display: flex;
            justify-content: center;


             .invitation_logo{
                width: 40%;
                max-width: 80px;
             }
        }
        .invitation_text_and_coin{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 300px;
            width: 90%;
            

                .invitation_text{
                    font-size: clamp(1.5em, 6vw, 2.5em);
                    font-weight: 400;
                    max-width: 220px;
                    text-align:left;
                    font-family: 'geomanistmedium';
                }
          
        }
        .invitation_text_secondary{
            font-size: clamp(1em, 4vw, 2em);
            text-align: center;
            font-weight: 600;
            max-width: 300px;
            font-family: 'geomanistlight';
            width: 90%;
            margin-bottom: 1em;
        }
         
        .invitation_btn_next_connect{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5em;
         
            margin-bottom: auto;
            padding-bottom: 100px;
           
            .btn_connect{
                width: 100%;
                padding: 16px;
                box-shadow: 0px 8px 0px #D9CA0C;
                font-family: 'geomanistmedium';
                font-weight: 400;
                font-size: 16px;
            }

         
            .btn_next{
              
                width: 100%;
                padding: 16px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                background-color: #ffffff;
                color: rgb(12, 12, 12);
                border: #E4DCDC solid 1px;
                width: 100%;
                max-width: 90vw;
                cursor: pointer;
                border-radius: 10px;
                transition: 0.3s;
                font-family: 'geomanistbold';
                font-weight: 400;
                font-size: 16px;
                
            }
        }
        
}