.reward-convert {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 10px; /* TODO : variabiliser */
    z-index: 1;
    .title {
        margin-bottom: 15px;
        font-size: 16px; /* TODO : utiliser la charte */
        font-weight: bold; /* TODO : utiliser la charte */
    }

    .convert-container {
        display: flex;
        width: 100%;
        gap: 20px;

        .convert-item {
            display: flex;
            flex: 1;
            gap: 0.5em;
            align-items: center;
            padding: 1.5em;
            background: white;
            border: 1px solid #E4DCDC;
            border-radius: 10px; /* TODO : variabiliser */
            box-shadow: 0 5px 0 #EAF128;

            &.disabled {
                background: lightgrey;
                opacity: 0.5;
                cursor: not-allowed;
            }

            .item-label {
                font-size: 13px;
                margin-top: 5px;
                font-weight: bold;
            }

            .convert-img {
                width: 35px;
            }
        }
    }
}
