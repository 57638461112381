@import '../../variables';

$purchase-button-height: 150px;
$local-border-radius: 0.6em;

.footer-container {
  &.open {
  }
}

.warning-message {
  position: absolute;
  bottom: $purchase-button-height;
  font-size: $font-size-normal;
  padding: $spacer-1 $spacer-3;
  background: $red;
  color: $white;
  border-radius: $local-border-radius;
}

.item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 $spacer-4;

  margin-bottom: $purchase-button-height;
  overflow-y: auto;
}

.purchase-button-container {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: $spacer-2;

  position: fixed;
  bottom: $spacer-5;

  width: 90%;
  gap: $spacer-6;
}

.footer-cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $button-gradient;
  border-radius: $local-border-radius;
  font-family: 'geomanistmedium'; // TODO : ne pas dupliquer
  color: $second-black;

  width: 100%;
  padding: $spacer-5;
}

.purchase-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: 'geomanistmedium';
  gap: $spacer-5;
  width: 100%;
  line-height: 2rem;
 

  &.disabled {
    pointer-events: none;
    background-color: $gray-lighter;
    border-radius: $local-border-radius;
    padding: $spacer-5;

    .color-label {
      color: $gray;
    }
  }
}

.bottom-btn-shadow{
  box-shadow: 0 8px 0 #D9CA0C;
}
.header_footer_cart{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacer-4;
  border-bottom: solid 1px $border-color;
  gap: $spacer-4;
  .header_footer_cart_text{
    font-size: $font-size-larger;
    font-family: 'geomanistmedium';
  }
 
}
.price_footer{
  position: fixed;
  height: 130px;
  padding-bottom: 80px;
  bottom: 0;
  width:100%;
  display: flex;
  padding: 0.5em 1.8em;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
  left: 50%;
  transform: translateX(-50%);
}
.price_footer::after{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  content: "";
  height: 1px;
  background-color: #E5E5E5;
}
.earn-point-container{
  display: flex;
  width: fit-content;
  padding: 0.3em;
  gap: 0.3em;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  color: #00D315;
  background-color: #D2FDD7;
  border-radius:6px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family:'geomanistmedium';

  &.red-mode{
    background: #FFDDDD;
    color: #EC305D;
  }
  .earn-point{
    color: #00D315;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 12px;
    height: 12px;
  }
}
