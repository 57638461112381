@import '../../variables';


.store_list_tuile {
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background: white;
  padding: 1em;
  border-radius: 10px;
  border: solid 1px #E4DCDC;
  transition: all 0.2s linear;
  margin-top: $spacer-5;

  &.children{
    width: 90%;
    margin-top: 0;
    height: 60px;
  }

  &.select_state{
    box-shadow: inset 0px 0px 0px 4px #F3F287;
  }

  &:active{
    transform: scale(0.98);
    opacity: 0.4;
  }

  .store_list_tuile_logo_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50px;
    overflow: hidden;
    padding: 1em;
    border-radius: 8px;
    background: #F4F4F4;

    img{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .store_list_tuile_name{
    font-size: 1.3em;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    .store_list_tuile_emplacement{
      opacity: 0.7;
    }
  }

  .store_list_tuile_arrow{
    display: flex;
    margin-left: auto;
    transform: rotate(-90deg);
    padding: 1em;
    position: relative;
    transition: all 0.2s linear;

    &.arrow_active{
      transform: rotate(0deg);
    }
  }

  .tuile_right_part{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .store_list_tuile_arrow{
      margin-left: 0 ;
    }
    .select_store_span{
      font-size: 1.3em;
      font-weight: 400;
      padding: 0.4em;
      border-radius: 5px;
      background: #F3F287;
      color: rgba(0, 0, 0, 0.418);
      font-weight: 700;
      margin-right: 0.5em;
    }
  }

}
