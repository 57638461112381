.checkscreen{
    height: 100%;
    min-width: 100%;
    /* background: linear-gradient(168.48deg, #FF627F -14.21%, #FFBA88 100%), #FFFEF1; */
    background-color: #BDF8A0;
    overflow: auto;
}
.headercheck{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
#check_logo{
    width: 15%;
}
.pineapplepay_text{
    font-size: 36px;
    font-family: 'poppinssemibold';
    font-weight: 600;
}
.checklist-header1{
    font-family: 'poppinsregular';
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
}
.checklist-header-text{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    line-height: 12px;
}
.checklist-header-date{
    width: 100%;
    display: flex;
    color: white;
    padding: 2%;
    justify-content: space-between;
    font-family: 'poetsen_oneregular';
    background: linear-gradient(96.63deg, #FF627F 13.87%, #FFBA88 87.26%);
    padding: 3% 5% 3% 5%;
}
.check_list{
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 10%;
    border-radius: 20px;
    align-items: center;
    background-color: white;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
}
.check-min-30{
    font-size: 18px;
    font-family: 'poppinsregular';
    font-weight: 600;
    text-align: center;
}
.check-min-30_price{
    font-family: "poetsen_oneregular";
    font-weight: 400;
    font-size: 25px;
}
/* text-center pt-12 */
.button-check-div{
    text-align: center;
    padding-top: 3rem;
}
.button-check{
    font-weight: 800;
    padding: 2.5rem 10rem 2.5rem 10rem ;
    border-radius: 1.5rem;
    color: white;
    text-decoration: none;
    border: none;
    background-color: black;
    font-size: 2.5rem;
    font-family: "poppinsregular";
}

@media screen and (max-width: 365px) {
    .checklist-header-text{
        width: 90%;
    }  
    .check-min-30_price{
        width: 25%;
    }
}
@media screen and (max-width: 345px) {
    .checklist-header-text{
        width: 110%;
    }  
    .check-min-30_price{
        width: 30%;
    }
}