@import "../_variables.scss";    
#installpopup{
    background-color: #000000a7;
    
    /* background: linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF);     */
  
}
.gradient_light{
    background: linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF);    
}
.textInstallation{
    align-self: center;
    color: black;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    width: 90%;

}

.arrowInstallation{


    border-color: #e1f7ed transparent transparent;
    border-style: solid;
    border-width: 20px 26px 0;
    bottom: 3px;
    height: 0;
    left: 42%;
    position: fixed;
    width: 0;
}

.header-svg-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .header-svg{
        width: 100%;
        height: 100%;
        max-width: 230px;
    }
}

.bold_blue{
    color: #3523B9;
    font-family: 'geomanistbold';
    font-weight:800;
}
.question-mark-account{
    position: absolute;
    margin: $padding;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 0;
    border-radius: 25px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.361);
    img{
        width: 28px;
        height: 28px;
    }
    width: 28px;
    height: 28px;
}