@import '../../variables';
#user-fidelity-card-detail{
  
    height: 100dvh;
}
.fidelity_card{
    min-height: 197px;
    height: 40%;
    width: 100%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 30px 0px rgba(39, 39, 39, 0.164);
    max-width: 400px;
    transition: all 0.2s linear;

    &:active{
        transform: scale(0.98);
        opacity: 0.4;
    }

    

    .logo_fidelity_card{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        max-height: 70px;
        padding-top: 0.5em;
        object-fit: contain;
        margin: 0 auto;

 
    }
    .black_bar_fidelity_card{
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background: #252525;
        position: relative;
        overflow: hidden;

        &::after{
            content: "";
            width: 30px;
            height: 100px;
            background: rgba(255, 255, 255, 0.247);
            position: absolute;
            right: 70px;
            top: -36%;
            transform: rotate(30deg);
        }
        &::before{
            content: "";
            width: 15px;
            height: 100px;
            background: rgba(255, 255, 255, 0.247);
            position: absolute;
            right: 40px;
            top: -27%;
            transform: rotate(30deg);
        }
        .title_fidelity_card{
            color: white;
            font-size: $font-size-normal;
            font-weight: 400;
            margin-left: 1em;
        }
       
        .arrow_fidelity_card{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            padding-right: 1em;
            height: 60%;
            fill: white;
            color: white;
            border-radius: 0 15px 15px 0;
            cursor: pointer;
            transition: all 0.2s linear;

            img{
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
    }

    .footer_fidelity_card{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;

        img{
            width: 90%;
            height: 80%;
            object-fit: contain;
        }
       
    }
}
.fidelity_tile{
    background-color: #ffffff;
    padding: 1em;
    width: 90vw;
    border-radius: 1em;
    gap:1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 0px 4px #f5f988;
    font-size: $font-size-normal;
    .body_fidelity{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.5em;

        img{
            height:150px
        }
    }
    .header_fidelity{
        display: flex;
        width: 100%;
        justify-content: space-between;

        .tiulaire_box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
        .title_titulaire{
            text-align: right;
        }
    }
    .logo_fidelity{
        height: 50px;
    }
    img{
        width: 100%;
        object-fit: contain;
        height: 100%;
    }
    .nom_titulaire{
        font-size: 1em;
        font-weight: 600;
        text-align: right;
        width: 100%;
    }
}

.avantage-fidelity{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    text-align: left;
    border-radius: 1em;
    flex-direction: column;
    padding: 1em;
    box-shadow: 0px 0px 0px 4px #fafda7 ,0px 0px 0px 1px #5d5c37;
    .header-avantage-fidelity{
        font-size: $font-size-large;;
        width: 100%;
        font-weight: bold;
        text-align: left;
    }

    .detail-avantage-fidelity{
        font-size: $font-size-normal;
        width: 100%;
        text-align: left;
    }
}

.info-fidelity{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    text-align: left;
    flex-direction: column;
    

    .header-info-fidelity{
        font-size: $font-size-large;;
        width: 100%;
        font-weight: bold;
        text-align: left;
    }
    .details-info-fidelity{
        font-family: 'geomanistRegular';
        font-size: $font-size-normal;
        width: 100%;
        font-weight: 500;
        text-align: left;
    }
}