@import 'variables';

body {
  box-sizing: border-box;
  margin: 0;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'intermedium';
  padding-bottom: env(safe-area-inset-bottom);
  background: rgb(255, 255, 255);
}

#root {
  height: 100%;
  overflow: hidden;
  --toastify-toast-max-height: 64px;
}

.home {
  height: 100%;
}

main {
  height: 100dvh;
  overflow: auto;
}

.scandit .scandit-logo {
  bottom: 20% !important;
}

.css-grxbm emhn3tr0{
  display: none;
}

iframe{
  border-radius: 0px;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

html{
  font-size: 62.5%; /*taille du texte dans tout ce qui est entre des balises html*/
  box-sizing: border-box; /* responsive, taille de la boite modulable */


  min-width: 100%;

  }
  .interMedium{
    font-family: 'intermedium';
  }


/*  */
@font-face {
  font-family: 'poetsen_oneregular';
  src: url('./font/poetsen one/poetsenone-regular-webfont.woff2') format('woff2'),
       url('./font/poetsen one/poetsenone-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}

@font-face {
  font-family: 'source_sans_proregular';
  src: url('./font/source sans pro/sourcesanspro-regular-webfont.woff2') format('woff2'),
       url('./font/source sans pro/sourcesanspro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}
@font-face {
  font-family: 'Nunito';
  font-weight: 600;
  src: url('./font/nunito-600/Nunito-SemiBold.woff2') format('woff2'),
      url('./font/nunito-600/Nunito-SemiBold.woff') format('woff');
  font-style: normal;

 font-display: block;
}
@font-face {
  font-family: 'latoregular';
  src: url('./font/lato/lato-regular-webfont.woff2') format('woff2'),
       url('./font/lato/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}
@font-face {
  font-family: 'interlight';
  src: url('./font/inter300-Light/Inter-Light.woff2') format('woff2'),
       url('./font/inter300-Light/Inter-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}

@font-face {
  font-family: 'intermedium';
  src: url('./font/inter500-Medium/Inter-Medium.woff2') format('woff2'),
       url('./font/inter500-Medium/Inter-Medium.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}

@font-face {
  font-family: 'interbold';
  src: url('./font/inter700-bold/Inter-Bold.woff2') format('woff2'),
       url('./font/inter700-bold/Inter-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}

@font-face {
  font-family: 'intersemibold';
  src: url('./font/inter600-Semibold/Inter-SemiBold.woff2') format('woff2'),
       url('./font/inter600-Semibold/Inter-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}

/* OPEN SANS */
@font-face {
  font-family: 'open_sanssemibold';
  src: url('./font/OpenSans600-Regular/opensans-semibold-webfont.woff2') format('woff2'),
       url('./font/OpenSans600-Regular/opensans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'open_Bold';
  src: url('./font/open-sans-bold/OpenSans-Bold.ttf') format('ttf');

  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'open_Bold';
  src: url('./font/open-sans-bold/OpenSans-Bold.ttf');
  font-weight: bolder;
}

@font-face {
  font-family: 'open_sansregular';
  src: url('./font/OpenSans400-Regular/opensans-regular-webfont.woff2') format('woff2'),
       url('./font/OpenSans400-Regular/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



@viewport {
  orientation: portrait;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: #353535;
}

*:focus {
  outline: 0;
  outline: none;
  scroll-behavior: smooth;
}
summary::-webkit-details-marker {
  display:none;
  }

.open-sans{
  font-family: 'open_sansregular';
}
.geomanistBold{
  font-family: 'geomanistbold';
  /* font-weight: lighter; */
}
.geomanistMedium{
  font-family: 'geomanistmedium';
}
.open-sans-bold{
  font-family: 'open_Bold';
}
button:active{
 opacity: 0.5;
}

.bg-gradient1 {
  background: $pikko-box-gradient;
  color: #353535;
}

.checkbox_border{
  background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
  display: flex;
  padding: 0.5em;
  width: 2.5rem;
  position: relative;
  height: 2.5rem;
  border-radius: 50%;
}
.checkbox{
  width: 20px;
  height: 20px;
  border-radius: 0.3em;
 border: solid 1px #5a5a5a;

  cursor: pointer;

  transition: 0.3s;
}

.pikko-btn {
  background: #e1ff26;
  background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 237, 71) 100%);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 900;

  /* border-bottom: 6px solid rgba(255, 207, 38, 0.5) */
}

.pikko-btn:active {
  background: linear-gradient(140deg, rgba(229, 255, 57, 0.7) 15%, rgba(255, 214, 65, 0.6) 100%);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.0);
  border-bottom-width: 0px;
  scale: 0.9;

}
.pikkogradient{
  background: rgb(225, 255, 38);
  background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
}
.underlyingPikkogradient{
}
.success{
  animation: scaling 1.5s ease linear;
  animation-delay: 3s;
}
.geomanistLight{
  font-family: 'geomanistlight';
}
@keyframes scaling{
 0%{
    scale: 1;
  }
25%{
    scale: 1.2;
  }
50%{
    scale: 1;
  }
75%{
    scale: 1.2;
  }
100%{
    scale: 1;
  }
}
.info{
  font-size: 12px;
  bottom: 0;
  position: absolute;
}
.info1{
  animation: apparition_dinfo 2s ease-in-out;
  animation-delay: 0s;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
}
.info2{
  animation: apparition_dinfo 1.5s ease-in-out;
  animation-delay: 3s;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
}
.info3{
  animation: apparition_dinfo 1.5s ease-in-out;
  animation-delay: 5s;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
}
.info4{
  animation: apparition_dinfo 1.5s ease-in-out;
  animation-delay: 7s;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
}

@keyframes apparition_dinfo{

  0%{
    opacity: 0;
    transform: translateY(100px);
  }
  10%{
    opacity: 1;
    transform: translateY(0px);
  }
  90%{
    opacity: 1;
    transform: translateY(0px);
  }
  100%{
    opacity: 0;
    transform: translateY(-100px);
  }
}
#invoice{
  background:linear-gradient(#fcfddd,#f1faee) ;
}

ul{
  list-style-type: none;
}
li{
  margin-top: 1.5rem;
}
button{
  background-color: transparent;
  color: inherit;

}
#textgradient{
  background: linear-gradient(167.51deg, #FFBA88 -14.16%, #FF627F 158.21%);;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}
#textgradient2{
  background: linear-gradient(168.48deg, #FF627F -14.21%, #FFBA88 100%);
  /* -webkit-background-clip: text; */
  background-clip: text;
  -webkit-text-fill-color: transparent;

}
#formScreen{
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
  background: linear-gradient(345.31deg, #BEF1F2 -29.3%, #FFD9DE 39.01%, #FAF3BF 143.27%);
  font-family: "poppinsregular";

}
#bg-gradient{
  background: linear-gradient(142deg, #ffc262 13.87%, #ff8f88 87.26%);
}

input{
  height: 37px;
  border: none;
  font-family: 'poppinsligth';
}

.Rating{
  background: linear-gradient(168.48deg, #e8ffdf -14.21%, #fdff88 100%);
  color: black;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
}

.recus{
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  color: #353535;
}
.opensansitalic{
  font-family: 'OpenSansItalic';
}
.OpenSansItalic{
  font-family: 'OpenSansItalic';

}
/* @media screen and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* GEOMANIST */

@font-face {
  font-family: 'geomanistregular';
  src: url('./font/Geomanist400-Regular/geomanist-regular-webfont.woff2') format('woff2'),
       url('./font/Geomanist400-Regular/geomanist-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'geomanistmedium';
  src: url('./font/Geomanist500-Medium/geomanist-medium-webfont.woff2') format('woff2'),
       url('./font/Geomanist500-Medium/geomanist-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'geomanistlight';
  src: url(
    './font/Geomanist_Light/Geomanist-Light.woff') format('woff'),
       url('./font/Geomanist_Light/Geomanist-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'geomanistbold';
  src:
       url('./font/Geomanist_Bold/Geomanist-Book.woff') format('woff');



}
/* POPPINS */
@font-face {
  font-family: 'poppinslight';
  src: url('./font/Poppins300-Regular/poppins-light-webfont.woff2') format('woff2'),
       url('./font/Poppins300-Regular/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;

}
@font-face {
  font-family: 'poppinsregular';
  src: url('./font/Poppins400-Regular/poppins-regular-webfont.woff2') format('woff2'),
       url('./font/Poppins400-Regular/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

 font-display: block;
}
@font-face {
  font-family: 'poppinsitalic';
  src: url('./font/Poppins400-Italic/poppins-italic-webfont.woff2') format('woff2'),
       url('./font/Poppins400-Italic/poppins-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('./font/Poppins500-Regular/poppins-medium-webfont.woff2') format('woff2'),
       url('./font/Poppins500-Regular/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;

}
@font-face {
  font-family: 'OpenSansItalic';
  src: url(
    './font/Open_Sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'poppinsmedium_italic';
  src: url('./font/Poppins500-Italic/poppins-mediumitalic-webfont.woff2') format('woff2'),
       url('./font/Poppins500-Italic/poppins-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'poppinssemibold';
  src: url('./font/poppins-600/poppins-semibold-webfont.woff2') format('woff2'),
       url('./font/poppins-600/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
 font-display: block;
}
@font-face {
  font-family: 'poppinsbold';
  src: url('./font/Poppins700-Regular/poppins-bold-webfont.woff2') format('woff2'),
       url('./font/Poppins700-Regular/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'poppinsextrabold';
  src: url('./font/Poppins800-Regular/poppins-extrabold-webfont.woff2') format('woff2'),
       url('./font/Poppins800-Regular/poppins-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



.tab-container {
  text-align: left;
  margin-top: 200px;
  width: 720px;
  background-color: white;
}

.tabs {
  display: flex;
  position: relative;
}

.tab-item {
  min-width: 80px;
  padding: 16px 20px 11px 20px;
  font-size: 20px;
  text-align: center;
  color: #c23564;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 5px solid transparent;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.5s ease;
}

.tab-item:hover {
  opacity: 1;
  background-color: rgba(194, 53, 100, 0.05);
  border-color: rgba(194, 53, 100, 0.1);
}

.tab-item.active {
  opacity: 1;
  background-color: rgba(194, 53, 100, 0.2);
  border-color: rgba(194, 53, 100, 1);
}

.tab-content {
  padding: 28px 20px;
}

.tab-pane {
  color: #333;
  display: none;
}
.tab-pane.active {
  display: block;
}

.tab-pane h2 {
  font-size: 24px;
  margin-bottom: 8px;
}
