
.bulle{
  transition: all 0.3s ease-in-out;
  background: rgb(225, 255, 38);
  background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 6rem;
  height: 6rem;
  opacity: 0;
 
}

.active_bulle{

  animation: slide_bulle_footer 0.2s ease-in-out forwards;
}

@keyframes slide_bulle_footer{
  0%{
    opacity: 0;
    scale: 0.5;
    transform: translate(-50%, 100%);
    
  }
  100%{
    opacity: 1;
    scale: 1;
    transform: translate(-50%, -50%);
  }
}
/* padding : 14px; */



footer .scanBtn{
  /* background-color: white; */
  /* border-top-left-radius: 100%;
  border-top-right-radius: 100%; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* top: 1px; */
  /* left: 0; */
  /* width: 8rem;
  height: 8rem; */
  top: -1.6rem;
  
  /* position:relative; */
}

footer .scanBtn:before,
footer .scanBtn:after {
position: absolute;
content: " ";
width: 16px;
height: 16px;
border-bottom: 4px solid white;
top: 8px;

}

footer .scanBtn:before {
border-bottom-right-radius: 100%;
left: -6px;
}

footer .scanBtn:after {
border-bottom-left-radius: 100%;
right: -6px;
}

.shake {
animation: shakeCart 0.4s ease-in-out forwards;
}


@keyframes shakeCart {
25% {
  transform: translateX(6px);
}
50% {
  transform: translateX(-4px);
}
75% {
  transform: translateX(2px);
}
100% {
  transform: translateX(0);
}
}