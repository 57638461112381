@import '../variables';

#popupPromo.popupAccount{
    top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 80%);
    border-radius: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    position: absolute;
    min-height: 400px;
    z-index: 999;
}

.contenaireinput_promo{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.box-gradient{
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    border-radius: 1.5rem;
    padding: 1.5rem;
    /* width: 133px;
    height: 140px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rotationtotal {
    animation: rotationtotal 1s ease-in-out forwards;
    opacity: 0;
    transform: rotateX(360deg);
}

@keyframes rotationtotal {
    0% {
        -webkit-transform: rotateX(360deg);
        -moz-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        transform: rotateX(360deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
    }
}
.contenaire_promo_screen {
    width: 100%;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


    .popupAccount{
         width: 90%;
        .contenaire_popup_inscription_top{
            padding: 1em;
            border-radius: 1em;
            width: 100%;
            gap: 1em;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: solid 1px #C1C9B3;
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            align-items: center;

            .close_popup{
            width: 32px;
            border-radius: 100px;
            height: 32px;
            background: rgba(40, 41, 37, 0.121);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 10px;



               img{
                width: 20px;
                height: 20px;
               }
            }

            &.row_top{
                flex-direction: row;

                .img_promo5_and_piece{
                    max-width: 80px;
                }
            }
            .img_promo5_and_piece{
                width: 100px;
            }



            .icon_popup_inscription{
                width: clamp(100px, 30%, 200px);
            }


            .offert_box{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 0.5em;
                margin-bottom: 0.5em;

                .greenprice{
                    color: #77B668;
                }
            }


    }
    .popup_promo_change_button{
        margin: 1em;

        .popup_promo_change_button_blue{
            color: #3523B9;
        }
    }
}
    .contaire_principale_promos-welcome{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 1.5em;

          .promotitle{
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 0.4em;
            width:100%;

               .bold,.promo_small_title{
                font-size: 2.5em;
                font-family: 'geomanistmedium';

                font-weight: 200;

               }
               .promo_small_title{
                font-size: 1.5em;
                transform:translateY(-3px)


               }

          }

    }


}

.promo-footer-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: $white;
    padding: $spacer-5 $spacer-6;
    gap: $spacer-6;

    .non_merci_promo{
        span{
            font-family: 'geomanistmedium';
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-weight: lighter;
            border: 1px solid #c0c2bd;
            padding: 1em;
            border-radius: 10px;
            font-size: $font-size-medium;
        }

        .arrow-right {
            margin-left: 7px;
        }
    }
}

.conversion-tile-container{
    display: flex;
    flex-direction: column;
    gap: $spacer-5;
    width: 100%;
    padding: 0 $spacer-3;

    .conversion-tile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $white;
        border: 1px solid $yellow;
        box-shadow: 0 5px 0 $yellow;
        padding: $spacer-6;
        border-radius: $border-radius;

        &.disabled {
            background-color: $gray-light;
            box-shadow: 0 5px 0 $gray;
            border-color: $gray;
        }

        .conversion-name-container {
            display: flex;
            align-items: center;
            gap: $spacer-3;

            .discount-type-img {
                width: 30px;
            }
            .conversion-name {
                font-size: 14px;
                font-family: "geomanistmedium";
            }
        }

        .conversion-cost-container {
            display: flex;
            align-items: center;
            gap: $spacer-2;

            .conversion-cost {
                font-size: 20px;
                font-family: "geomanistmedium";
                font-weight: bolder;
            }

            .picoin-img {
                width: 20px;
            }
        }
    }
}

.promoscreen_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    gap: 2.3em;

    .promo-container {
        width: 100%;
        height: calc(100%);
        padding-bottom: 80px;

        .promo-value {
            font-size: 28px;
            font-family: 'geomanistbold';
            width: 60px;
        }
    }

    .container_promos_welcome{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;


            .promo_visit_texte{
                font-size: 1.5em;
                font-family: 'geomanistregular';
                color: #aeaeae;
                font-weight: 200;

                &.light{
                    font-family: 'geomanistregular';
                    font-size: 1.3em;
                }
            }

            .welcome_promo_card{
                background: #F3FEE9;
                border: solid 1px #C1C9B3;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: clamp(100px, 32%, 520px);
                padding: 1em;

                .promologo{
                    max-width: 80px;
                    max-height: 49px;


                    img{
                        width: 100%;
                        height: 100%;
                    }
                    .promo_piece_translate_y{
                        transform: translateY(-30px);


                    }

                }
                &.activable{
                    box-shadow: 0px 0px 10px 0px #1717173b;
                }
                &.unactivable{
                    pointer-events: none;
                    background: #E1F1D3;
                    border: none;
                }

                .promo_offert_box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 0.5em;



                    .promo_offert_price{
                        font-size: 2em;
                        font-family: 'geomanistmedium';
                    color: #ABB668;
                    font-weight: 200;

                    &.unactivable{
                        color: #77B668;
                    }


                    }
                    .promo_offert_title{
                        font-size: 1.6em;
                        font-family: 'geomanistmedium';
                        font-weight: 200;


                    }
                }
            }



    }
    .promo_active_button{
        width: 100%;
        margin-top: 1em;

           .promo_active_button_desactiver{
            font-size: 1.1em;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'geomanistregular';
            color: #212121;
            border: solid 1px #C1C9B3;
            background: none;
            border-radius: 5px;

           }
    }

    .non_merci_promo{
        font-size: 1.5em;
        font-family: 'geomanistregular';
        color: #aeaeae;
        font-weight: 200;
        border: 1px solid #c0c2bd;
        padding: 1em;
        border-radius: 10px;
        cursor: pointer;
    }
}
