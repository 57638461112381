@import '../../variables';

// TODO : Trier cette classes css et la diviser en plusieurs
.brand-tile {
    .brand-name {
        margin-top: 3px;
        font-size: $font-size-normal;
        font-family: 'geomanistmedium';
    }

    .brand-promo-label {
        display: flex;
        align-items: center;
        font-family: 'geomanistregular';
        font-size: $font-size-normal;
        gap: 2px;
        margin-top: 5px;

        .promo-highlight {
            font-weight: bold;
            color: $green;
        }

        .bold-05-opacity{
            font-family: 'geomanistbold';
            opacity: 0.6;
        }

        .coin-img {
            width: 12px;
            margin-left: 2px;
        }
    }
}

#brand-partner-screen {
    height: 100%;

    .brand-partner-screen-body {
        height: calc(100% - $height-scan-footer);
        padding: $spacer-6;
        overflow-y: auto;

        .brand-partner-content {
            display: flex;
            flex-direction: column;
            width: 100%;

            .brand-partner-back {
                margin-bottom: 10px;
            }

            .brand-partner-title {
                width: 100%;
                text-align: center;
                margin: $spacer-5 0;
                font-size: $font-size-huge;
                font-family: 'geomanistmedium';
            }
        }
    }
}

#brand-detail-screen {
    height: 100%;

    .brand-detail-screen-body {
        height: calc(100% - $height-scan-footer);
        padding: $spacer-6;
        overflow-y: auto;

        .brand-content {
            display: flex;
            flex-direction: column;
            width: 100%;

            .brand-back {
                margin-bottom: 10px;
            }

            .pikko-brand-offer {
                padding: 10px;
                margin-top: 10px;
                margin-bottom: 20px;
                background: $pikko-box-gradient;

                .pikko-brand-offer-title {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 3px;
                }
            }

            .brand-label {
                font-size: $font-size-large;
                font-weight: bold;
            }

            .brand-text {
                font-size: $font-size-normal;
                font-family: 'geomanistregular';
                line-height: 20px;
            }
           

            .brand-name {
                margin-bottom: 5px;
            }
        }
    }
}

.brand-conversion-label {
    position: absolute;
    display: flex;
    align-items: center;
    top: 6px;
    left: 6px;
    padding: 3px 3px 3px 5px;
    font-size: $font-size-little;
    font-weight: bold;
    background-color: rgba(211, 211, 211, 0.849);
    border-radius: 5px;

    .coin-img {
        width: 14px;
        margin-left: 2px;
    }

    &.fixed {
        position: unset;
        background-color: transparent;
        border: 1px solid $gray-light;
    }
}

.brand-logo-container {
    position: relative;
    display: flex;
    width: 160px;
    height: 100px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin-top: 5px;
    justify-content: center;

    &.full-width {
        width: 100%;
        height: 200px;
    }

    .brand-logo-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
    }
    
}

.brand-list {
    padding: 10px 0;

    .brand-title {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 10px;
        text-align: center;
        font-family: 'geomanistmedium';
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .brand-container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        gap: 15px;
        padding-bottom: 10px;
    }
}

