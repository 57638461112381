.slider-for-user-scroll-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 4em;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar{
        width: 0;
        height: 0;
    }

    .slider-for-user-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    
        .slider-for-user-text-one{
            font-size: 24px;
            font-weight: 600;
            font-family: 'geomanistmedium';
            text-align: center;
            width: 100%;
        }
        .slider-for-user-text-two{
            font-size: 20px;
            font-weight: 600;
            color: rgb(148, 155, 155);
            font-family: 'geomanistmedium';
            text-align: center;
            width: 100%;
            font-weight: normal;
        }   
    }
    
}

.slider-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding:0.8em;
    border: solid 1px #3b3b3b5a;
    border-radius: 12px;
    font-family: 'geomanistmedium';
    font-weight: 400;
    font-size: 16px;
}

.containerGift{
   display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .gift_card_user_info{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        width: 84px;
        height: 68px;
        padding: 0.5em;
        border: solid 1px #3b3b3b21;
        border-radius: 12px;
        font-family: 'geomanistmedium';
        font-weight: 400;
        font-size: 16px;
    
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
           
        }
    }
}




