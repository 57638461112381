@import '../../variables';

.deconnect-button{
    text-align: center;
    padding: 5px 7px;
    border-radius: 8px;
    color: #e80f00;
    background: #f8e9e8;
}
.back-button {
    width: fit-content;
    padding-right: 10px;
    width: 100%;
    position: relative;

    .back-button-logo{
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 25px;
        transform: translate(-50%,0);
    
    }

    &.absolute {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1000;
    }
}

.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    font-family: 'geomanistmedium';
    font-weight: lighter;
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 251, 38) );
    border-radius: 0.6em;
    box-shadow: 0 8px 0 #D9CA0C;
    transition: all 0.2s ease-in-out;

    &.disabled {
        background: $gray-light;
        cursor: not-allowed;
        box-shadow: 0 8px 0 $gray;
        transition: none;
    }

    .icon-button {
        margin-left: 8px;
        max-height: 24px;
    }

    &:active {
        opacity: 0.8;
        box-shadow: 0 0 0 #D9CA0C;
        transform: translateY(8px);
    }
}


.secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 50px;
    background: $white;
    color: $black;
    font-weight: bold;
    border-radius: 20px;
    border: 1px solid $border-color;

    &.disabled {
        background: lightgrey;
    }

    .label {
        color: $gray-darker
    }

    .icon-button {
        width: 16px;
        margin-right: 8px;
    }
}
.recherchePromos{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    gap: 0.5em;
    font-family: 'geomanistmedium';
    background: #FF6564;
    color: white;
    border: solid 1px $border-color;
    border-radius: $border-radius-round;
    font-size: 12px;
    font-weight: 400;

}
.promosResult{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    gap: 0.5em;
    font-family: 'geomanistmedium';
    background: #FFDDDD;
    color: #EC305D;
    border: solid 1px $border-color;
    border-radius: $border-radius-round;
    font-size: 12px;
    font-weight: 400;
}
.service_indisponible{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 60px);
    height: 40px;
    gap: 0.5em;
    font-family: 'geomanistmedium';
    background: #B8B8B8;
    color: #221725;
    border: solid 1px $border-color;
    border-radius: $border-radius-round;
    font-size: 12px;
    font-weight: 400;
}
.refresh_promos{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    width:45px;
    font-family: 'geomanistmedium';
    background: #B8B8B8;
    border: solid 1px $border-color;
    border-radius: $border-radius-round;
    font-size: $font-size-normal;
    font-weight: 400;

    img{
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

}
.store-selection-button { /* TODO : mettre cette classe css dans un fichier css dédié */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    gap: 15px;
    background: white;
    border-radius: 10px;
    border: 1px solid lightgrey;

    .store-action {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
    }

    .store-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;

        .store-name-content {
            display: flex;
            align-items: center;

            .store-img { // TODO : mettre en commun
                height: 40px;
                width: 40px;
                object-fit: contain;
                border-radius: 8px;
                background: #F4F4F4;
            }

            .store-name {
                margin-left: 10px;
                color: grey;
            }
        }

        .store-city { /* TODO : gérer le ellipsis */
            text-align: center;
            padding: 5px 7px;
            border-radius: 8px;
            color: $blue;
            background: #ebe8f8;
        }
    }
}
