.slider-container {
    width: 100dvw;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all 0.5s ease;
    transform: translateY(110%);
    height: 100dvh;

    &.visible {
      transform: translateY(0);
    }
  }
  
  .sliderTuto {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
    overflow-x: hidden; /* Pour cacher les diapositives qui dépassent du conteneur */
  }
  
  .slideTuto {
     /* Largeur de 100% pour chaque diapositive et hauteur de 100% */
    position: absolute;
    height: 100dvh;
    transition: all 0.5s ease;
    width: 100vw;
   
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 1em;
    gap: 1em;
    width: 85%;
    border: solid 1px #cac5c5;
    background: rgb(252, 252, 252);
    border-radius: 10px;
    color: rgb(24, 24, 24);
    outline: none;
    transition: all 0.3s ease;
    z-index: 1000;
    box-shadow: 0px 0px 0px 5px rgba(212, 255, 0, 0);
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    left: 50%;
    bottom: 1em;
    transform:  translateX(-50%);
  }
  .next:active,.deploy_button:active{
    scale: 0.9;
    box-shadow: 0px 0px 0px 5px rgb(212, 255, 0);
  }
  
  .fixed_bottom {
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 1em;
    padding: 1em;
    z-index: 1000;
  }

  .dots {
    text-align: center;
    margin-top: 10px;
    position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  
  .dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #d3d2d2;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    scale: 1.5;
    background: linear-gradient(90deg, rgb(255, 38, 0) 0%, rgba(255, 230, 1, 0.918) 100%);
  }
  