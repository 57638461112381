@import '../../variables';

.fidelity-login {
  display: flex;
  flex-direction: column;
  background: $pikko-box-gradient;
  width: 100%;
  height: 100%;
  padding: $spacer-6;
  gap: $spacer-6;

  .fidelity-card-id {
    color: $gray;
    font-size: 16px;
    font-family: 'geomanistregular';
  }

  .fidelity-context {
    font-size: 20px;
    font-family: 'geomanistbold';
  }

  .signin-popup {
    background: #00b3ff;
    width: 75%;
    max-height: 75%;
  }

}

.input_box_fidScreen{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px;
  height: 51px;
  border-radius: $border-radius;
  width: 100%;
  border: solid 1px $gray-light;
  overflow: hidden;
  font-family: 'geomanistregular';
  background: white;
  .input{
    border: none;
    width: 85%;
    padding-left: 1em;
    font-family: 'geomanistregular';
    font-weight: 600;
    height: 100%;
    margin: none;
    outline: none;
  }
  img{
    width: 25%;
    padding-right: 1em;
    height: 90%;
    border-radius: $border-radius-light;
    object-fit: contain;
  }
}
.titre_scanfid{
  font-size: 22px;
  color: white;
  font-family: 'geomanistbold';
  text-align: center;

}