@import '../../variables';

.notify-label {
    font-size: 14px;
}

.notify-button {
    border-radius: 0.6em;
    display: flex;
    align-items: center;
    font-family: 'geomanistmedium';
    color: #353535;
    justify-content: center;
    padding: $spacer-4 $spacer-7;

    &--confirm {
        background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 251, 38) );
        margin-right: $spacer-2;
    }

    &--cancel {
        outline: 1px solid $gray-light;
        margin-left: $spacer-2;
    }
}
