.pay-form {
    width : 60%;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .pay-form > div {
    margin-top: 1rem;
  }

  .paygreen-pan-frame, .paygreen-cvv-frame, .paygreen-exp-frame {
    background-color: white;
    padding-left: 1em;
    border: 1px solid #00000038 !important;
    border-radius: 1rem !important;
    box-shadow: none !important;
  }

  .css-1kg5k5z {
    background: red !important;
  }

  .paygreen-pan-frame, .paygreen-cvv-frame:focus, .paygreen-exp-frame:focus{
    outline: none;
  }
  .pg-payment-method{
    font-size: 10px !important;
  }

  /* .line {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

  }

  .line > div {
    max-width: 48%;
    box-shadow: none !important;
  } */

  #paygreen-reuse-checkbox-container {
    margin-top: 1rem;
    height: 60px;
  }

  .checked_svg{
    border-color: transparent;
    background: #04b404;
    animation: jelly 0.6s ease;

  }
