.stepContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: none;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 0 4em;
}

.stepbar{
    width: 100%;
    height: 5px;
    position: relative;
    display: flex;
    background: rgb(209, 209, 209);
    border-radius: 200px;
    transition: all 0.5s ease-in-out;
}
.containerStep{
   width: calc(100%/3 - 0.5em);
    height: 100%;
    gap: 0.5em;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items:center ;
}
.containerStep p{
    font-weight: 600;
    font-size: 11px;
    opacity: 0.5;
}
.stepbar.active:nth-child(1){
    background: linear-gradient(100deg, rgba(225, 255, 38, 1) 0%, rgb(255, 233, 38) 100%);
    
}
.pview p{
    opacity: 1;
}
.stepbar.active:nth-child(2){
    background: linear-gradient(100deg, rgb(247, 231, 17) 0%, rgb(233, 255, 38) 100%);
   
}
.stepbar.active:nth-child(3){
    background: linear-gradient(100deg, rgba(225, 255, 38, 1) 0%, rgb(255, 233, 38) 100%);
    }

.bouncess {
    -webkit-animation: bouncess 0.6s ease-in-out forwards;
    animation: bouncess 0.6s ease-in-out forwards;
    transform-origin: center bottom;
   
}

@keyframes bouncess {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
       
    }
}

