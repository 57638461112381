@import '../../variables';

$edit-icon-width: 25px;
$store-icon-width: 40px;

.scan-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height-scan-footer;
    padding: $spacer-5;
    gap: $spacer-3;
    border-top: 1px solid lightgrey;
    background-color: white;

    .footer-item {
        display: flex;
        width: 50%;
    }

    .edit-img-container {
        display: flex;
        align-items: center;

        .edit-img {
            width: $edit-icon-width;
        }
    }

    .store-info {
        display: flex;
        align-items: center;
        width: calc(100% - $edit-icon-width - $spacer-4);

        .store-detail {
            width: calc(100% - $store-icon-width);
            margin-left: 5px;

            .store-name {
                color: $blue;
            }

            .store-city {
                color: $gray;
            }

            .store-name, .store-city {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $font-size-little;
                font-family: 'geomanistregular';
                font-weight: bold;
            }
        }
    }

    .store-img {
        height: $store-icon-width;
        width: $store-icon-width;
        object-fit: contain;
        border-radius: 8px;
        background: #F4F4F4;
    }
}
