.deletepayment{
    display: flex;
    width: 80px;

    justify-content: flex-end;
    align-items: center;
}
.payment-popup-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
#labelCheckbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
    margin: 1em 0;
    font-size: 1.4rem;
    font-weight: 400;
    color: #5A5A5A !important;

}
.titre_popup{
    font-size: 22px;
    word-wrap: break-word;
    font-family: 'geomanistmedium';
    font-weight: 500;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;

    span{
        max-width: 156px;
    }
}
.no-eligible{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    font-family: 'geomanistbold';
    padding: 0.5em;
    margin: 1em;
    margin-top: 2em;
    text-align: left;
    background: #f3ef7070;
    color: rgba(0, 0, 0, 0.402);
    border: solid 1px $border-color;
    border-radius: $border-radius-round;
    font-size: 13px;
    font-weight: 400;


    img{
        width: 16px;
        opacity: 0.4;
    }
}
//payzen

.kr-embedded input.kr-input-field::placeholder,.kr-embedded .kr-input-relative-wrapper #inputField {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family:Verdana, Geneva, Tahoma, sans-serif !important;
}