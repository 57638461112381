.pikko-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .pikko-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        position: relative;
        border-radius: 1em;
        overflow: hidden;
        gap: 0.2em;

    .background_light {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

$grey: #868686;
$light-grey: #D9D9D9;

.pikko-point-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2px;

  .pikko-point-title {
    font-size: 16px;
    color: $grey;
    font-family: 'geomanistregular';
  }

  .pikko-point-amount {
    position: relative;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 10px;

    &.column {
      flex-direction: column-reverse;
      gap: 0;

      .picoin-img {
        width: 60px;
      }
    }

    .pikko-point {
      font-size: 48px;
      //filter: drop-shadow(0 0 0.75rem black);
      font-family: 'geomanistmedium';
      font-weight: bolder;
    }

    .picoin-img {
      width: 40px;
    }

    .earn-point-container {
      position: absolute;
      transform: translateX(110%);
      font-size: 14px;
      text-align: left;
      max-width: 90px;
      right: 0;
      display: inline;
      background-color: #86868600;

      .earn-point {
        font-size: 16px;
        font-family: 'geomanistmedium';
      }

      div {
        color: $green;
      }
    }
  }

  .pikkobox-conversion-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .price {
      margin: 0 10px;
      font-size: 16px;
      font-family: 'geomanistregular';
    }

    .horizontal-bar {
      display: flex;
      width: 40px;

      .bar {
        width: 100%;
        height: 1px;
        background: $light-grey;
      }
    }
  }
}
