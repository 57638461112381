
@import '../../variables';
.navigation-menu {
    position: absolute;
    z-index: 2;
    top: 0;
    left: -90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    height: 100%;
    width: 90%;
    padding: 20px;
    z-index: 2;
    transition: left 0.5s ease 0s;
    background: $white;

    &.open {
        transition: left 0.5s ease 0s;
        left: 0;
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-size: 18px;

        .close-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            .cross {
                width: 30px;
                height: 30px;
                margin-right: 5px;
                rotate: 45deg;
            }
        }
        .navigation-liste{
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* 2 colonnes égales */
            grid-template-rows: repeat(2, 1fr); /* 2 lignes égales */
            gap: $spacer-4;

            .navigation-item {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                border: solid 0.15rem $border-color  ;
                padding: $spacer-3;
                min-height: 93px;
                border-radius: $border-radius;
                background: $white;

                .nav-icon {
                    width: 24px;
                }

                .navigation-label {
                   margin-top: $spacer-2;
                   height: 40px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   font-size: $font-size-medium;
                }
            }

        }

        .navigation-item-help{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            gap: $spacer-3;
            font-size: $font-size-large;
        }
    }

    .profile {
        .username {
            font-size: 18px;
            font-weight: bold;
        }

        .action {
            font-size: 16px;
            color: grey;
        }
    }
}

.mask {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: grey;
    opacity: 0.6;
}
