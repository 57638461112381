@import '../../variables';
.profil-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    background-color: #f5f5f5;
    border-radius: $border-radius;
    box-shadow: inset 0px 0px 10px rgba(39, 39, 39, 0.384);
    overflow: hidden;

    .profil-box-button{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $spacer-2;

        img{
           width: 60px;
        }
    }
    .profil-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .profil-box-name{
            font-size: $font-size-large;
            font-weight: bold;
            color: $second-black;

            &.email {
                font-weight: normal;
                font-size: $font-size-medium;
            }
        }

        .profil-box-second-text{
            font-size: $font-size-medium;
            color: $second-black;
            opacity: 0.6;
            font-weight: bold;
        }
    }
}
