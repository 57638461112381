@import '../../variables';

.gift-surpise-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: $spacer-1;
    
    .pikko-gradient{
        padding: $spacer-3 1px;
        border-radius: 10px;
        padding: 2px;
        width: 100%;
        height: 100%;
        background: rgb(225, 255, 38);
        background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
       
        &.none{
            background: none;
            padding: 0;
        }

        .gift-surprise-box{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $spacer-1;
            padding: $spacer-4;
            padding-top: 0px;
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            
            &.pink{
                background: linear-gradient(90deg, #FF627F -14.21%, #ffdb11 100%);

                .gift-surprise-top .gift-surprise-text{
                    color: white;
                }
            }
            .gift-surprise-top{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: fit-content;
                max-height: 110px;
                gap: $spacer-3;

                .gift-surprise-text{
                    font-size: clamp(18px, 12vw, 32px);
                    text-align: left;
                    width: 50%;
                    font-weight: bold;
                    color: $second-black;
                }
                 
                .gift-surprise-svg{
                    width: 50%;
                    max-width: 100px;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            
            .pikkobar{
                position: relative;   
                
                .barrepikkotrue{
                    position: relative;  
                }
                .chat-bubble {
                    position: absolute;
                    padding: .5em;
                    background-color: #EDEDED;
                    box-shadow: 0px 0px 5px 0px #1511118c;
                    border-radius: 0.4em;
                    font-weight: bold;
                    min-width: 20px;
                    font-size: 1.5em;
                    right: -20px;
                    z-index: 1;
                    top: -30px;
                    text-align: center;
                    font-size: $font-size-little;

                    .chat-bubble-arrow-border {
                        position: absolute;
                        bottom: -1em;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0.5em;
                        border-color: #EDEDED transparent transparent transparent;
                    }
                    .chat-bubble-arrow {
                        
                        height:0;
                        width:0;
                        position:absolute;
                        bottom: -1em;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
               
                
  
            }
            
        }
    }
}