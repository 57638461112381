.ticket-screen{
  .no-ticket{
    width: 100%; 
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 64px;
    
      .cart-empty{
        font-size: 1.5em;
        text-align: center;
        padding-left: 3rem;
        padding-right: 3rem;
        
      }

  }
  
  .borderTickets{
    border: #E4DCDC solid 1.4px ;
    overflow: hidden;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    margin-bottom: 2rem;
    font-size: 1.5rem;


     .ticketsHeader{
      display: flex;
      gap: 1.5rem;
      width: 100%;
      padding: 2rem;
      justify-content: space-between;
      padding-bottom: 0em;

      .priceTicketScreen{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        overflow: hidden;
        font-size: clamp(8px,4vw,20px);
        font-family: 'Geomanist', sans-serif;
        font-weight: bold;

        
      }
  
      .logoDateName{
        display: flex;
        gap: 2rem;



        .nameTime{
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .storeName{
            font-weight: bold;
            padding-top: 5px;
            font-size: 16px;
          }

          .dateTime{
            display: flex;
            flex-direction: column;
            height: 5.5rem;
            color: #A3A3A3;;
            padding-top: 8px;
            font-size: 14px;
          }
        }

        .logoStore{
          border-radius: 1em;
          border-style: solid;
          border-width: 1px;
          border-color: #e2e2e2;
          height: 55px;
          width: 55px;

          .logoPhoto {
            width: 100%; 
            height: 100%;
            object-fit:contain;
          }
        }
      }
    }
    
    .ticketFooter{
      display: flex;
      align-items: center;
      background-color: white;
      color: black;
      max-height: 0;
      width:100%;
      flex-wrap: wrap;
      transition: all 500ms; 

      &.ticketFooterFirst{
        max-height: 250px;
        border: none;
        width: 100%;
        
      }
  
      .detailsTicketScreen{
        width: 100%;

        .productsTicketScreen{
          display: flex;
          flex-direction: column;
          width: 100%; 

          .clickProducts{
            display: flex;
            padding: 2rem;
            height: 5rem;
            align-items: center;
            justify-content: space-between;
            border-style: solid;
            border-color: #BFB6B6;
            border-top-width: 1px;
            border-left-width: 0;
            border-right-width: 0;
            border-bottom-width: 1px;


            .numberProducts{
              font-size: 1.5rem;
              font-family: 'Geomanist', sans-serif;
            }
            
            
            .arrowTicketScreen{
              height: 1.5rem;
              width: auto;
              transform: rotate(270deg);
            }
          }
        
          .separator{
            display: flex;
            align-items: center;
            background-color: white;
            color: black;
            max-height: 0;
            width: auto;
            height: 100%; 
            border-style: solid;
            border: none;
            width: 100%;
            flex-wrap: wrap;
            overflow: hidden;
            transition: all 200ms; 
          
            &.underSeparator{
              max-height: 500px;
              overflow-y: auto;
              transition: all 0.25s;
            }

            .productsName{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              padding-left: 2rem;
              padding-right: 2rem; 

              .ProductsNamePicturePrice{
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                padding-bottom: 1rem; 

                .productsTitle{
                  display: flex;
                  gap: 1rem;
                  align-items: center;

                  .productsPicture{
                    height: 3rem;
                    border-radius: 0.5em; 
                  }
                }
              }
            }
          }
        }
      }
        .refFooter{
          display: flex;
          flex-direction: column;
          padding: 1rem 2rem 2rem 2rem;
          gap: 2rem; 

          .detailRef{
            display: flex;
            justify-content: space-between;
            gap: 1rem; 

            .methodOrder{
              color: #A3A3A3;
            }

            .idTicketScreen{
              font-family: 'open_Bold';
            }
          }
        }      

    }
  }
  @media screen and (max-width:335px) {
  
    .priceTicketScreen{
      font-size: 14px;
    }
    
  }
}