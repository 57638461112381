// colors
$white: #ffffff;
$black: #000000;
$second-black: #353535;

$gray-lighter: #F5F5F5;
$gray-light: #BABABA;
$gray: #727272;
$gray-dark: #444444;
$gray-darker: #353535;

$green: #4FDC4C;
$blue: #3523B9;
$orange: #F49866;
$yellow: #EAF128;
$red: #da1d36;

// font-size
$font-size-mini: 10px;
$font-size-small: 11px;
$font-size-little: 12px;
$font-size-normal: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-larger: 22px;
$font-size-huge: 26px;

// padding / margin
$padding: 20px;
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer-3: 0.75rem;
$spacer-4: 1rem;
$spacer-5: 1.5rem;
$spacer-6: 2rem;
$spacer-7: 3rem;
$spacer-8: 4rem;
$spacer-9: 5rem;
$spacer-10: 6rem;
$spacer-11: 7rem;
$spacer-12: 8rem;
$spacer-13: 10rem;
$spacer-14: 12rem;

$border-radius-light: 5px;
$border-radius: 12px;
$border-radius-round: 25px;

// box shadows

// variable components
$height-pikko-point-header: 28rem;
$height-scan-footer: 90px;

$pikko-box-gradient: linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF);
$button-gradient: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 251, 38) );
$height-back-button: 50px;


//border-color
$border-color: #e1e0e0;
