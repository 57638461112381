@import '../../variables';

#page_account {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .page_account_body {
        position: relative;
        height: calc(100% - $height-scan-footer);
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        &.height_normal {
            height: 100%;
        }

        .page_account_content {
            height: 100%;

            .brand-list {
                height: calc(100% - $height-pikko-point-header);
            }
        }
    }
}
