@import "../../variables";

.wallet-container{
    width: 100%;
    height: calc(100% - 110px);
    display: flex;
    padding: $spacer-6;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .wallet-card{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        max-width: 300px;
        height:40px ;


        .wallet-card-img-box{
            width: 60px;
            height: 40px;
            border-radius: 6px;
            overflow: hidden;
            border: 1px solid #3b3b3b5a;

            .wallet-card-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .wallet-card-text-group{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
            width: calc(100% - 60px);

            .wallet-card-text{
                font-family: 'geomanistmedium';
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 20px;

                span{
                    font-size: clamp(10px, 6.5vw, 30px);
                    height: 20px;
                    transform: translateY(-12px);
                }

            }
        }
    }

    .empty-text{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
        text-align: center;
        height: 100%;
        width: 100%;
        flex-direction: column;
        gap: 10px;

        .empty-text-title{
            font-size: 24px;
            font-family: 'geomanistmedium';
        }
        .empty-text-description{
            font-size: 16px;
            font-family: 'geomanistregular';
            opacity: 0.6;
        }

    }

}
