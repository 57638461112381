@import '../../variables';

.contenaireNumberFidelity{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    flex-direction: column;
    padding-top: 80px;



    .titre_fidelityscreen{
        font-size: 1.5em;
        font-weight: 600;
        text-align: left;
        width: 100%;
    }
}
.footer_fidelity{
    span{
        font-weight: bolder;
    }
}
.fidelity-card-button-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: $spacer-7;
}

.contenairestoreLogoFid{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 1em;
    font-size: $font-size-medium;
    font-family: 'geomanistbold';
    border-radius: 10px;
    box-shadow:0 0 5px rgba(0, 0, 0, 0.361), 0px 0px 0px 6px #F3F287;
    background: white;
    padding: 10px;
    width: 100%;
    img{
        background: rgb(240, 239, 239);
        width: 50px;
        height: 40px;
        padding: 5px;
        border-radius: 7px;
        object-fit: contain;
    }

}
.btn_fidelitycardScreen{
    background: white;
    border: solid 1px $border-color;
    border-radius: $border-radius;
    font-size: $font-size-medium;
    height: 51px;
    display: flex;
    font-family: 'geomanistmedium';
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    font-weight: 500;
}
.ScanFidelity{

    .contenairestoreLogoFid_box{
        position: fixed;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        width: 90%;

        h1{
            color: white;
            font-size:clamp(1.5em, 5vw, 2em);
            font-weight: 500;
            text-align: center;
            width: 100%;
        }
    }
    .absolute_btn_fidelityscan{
        position: fixed;
        bottom: 15%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2em;
        width: 100%;
        transform: translateX(-50%);
        z-index: 10;
        color: white;
    }

    .indication_fidscan{
        color: white;
        text-decoration: underline;
        font-size: $font-size-medium;
    }
    .saisie_manuelleFidscan{
        color: white;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 1em;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        font-size: $font-size-medium;
        min-width: max-content;
        font-family: 'geomanistbold';
        padding: 1em;
       background: rgba(255, 255, 255, 0.258);
       border: 1px solid white;

    }
    .btn_fidelitycardScreen{
        color: white;
    }
}

.ScanFidelity .scandit.scandit-container .scandit-viewfinder::before {
    content: "";
    position: absolute;
    inset: 0;
    background: none;
    border: 2px solid white;
    margin: -2px;
    border-radius: 25px;
    mask:
        linear-gradient(0deg, #000 calc(2*var(--b)), #0000 0) 50% var(--b)/calc(10% - 2*var(--w)) 100% repeat-y,
        linear-gradient(-90deg, #000 calc(2*var(--b)), #0000 0) var(--b) 50%/100% calc(10% - 2*var(--w)) repeat-x,
        linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
    mask-composite: destination-out;
    mask-composite: exclude;
}

.user-fidelity-card-detail-content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: $spacer-6;
    padding-bottom: 80px;
    height: calc(100% - $height-scan-footer);

    .titre_fidelityscreen{
        font-size: 1.5em;
        font-weight: 600;
        text-align: left;
        width: 100%;
    }
}