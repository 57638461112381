@import '../../variables';

.reward-screen {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .reward-container {
        display: flex;
        height: 100%    ;
        flex-direction: column;
        height: calc(100% - $height-pikko-point-header);
        padding: 15px;
        gap: 15px;
        margin-bottom: 50px;
    }
}
.principal_drawer{
    display: flex;
    background-color: #E1F1D3;
    border-radius:1.2em;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    height: max-content;
    padding: 1em 2.5em 0em 2.5em;

    width: 90vw;
    .Titre_svg{
        width: 100%;
        margin-bottom: -0.5em;
        height: fit-content;

        img{
            object-fit: contain;
            height: 100%;
        }
    }
    .partOfCardGift{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        padding-top: 1em;
        gap: 8px;

        .reward_creation_account_div{
            display: flex;
            gap: 10px;
        }
            img{
                width: 20px;
            }
    }

    .text_un{
        font-size: $font-size-larger;
        font-family: 'geomanistbold';
        font-weight: bold;
    }
    .text_deux{
        font-size: 12px;
        font-family: 'geomanistbold';
        font-weight: 400;


    }
    .green{
           color: #6DA673;
       }
    .text_trois{
        font-size: $font-size-medium;
        font-family: 'geomanistbold';
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 0.3em;
        gap: 0.5em;
    }
}
.backblur{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;

    &.close{
        display: none;
    }
}
.drawerSpeedChallenge{
    width: 100dvw;
    position: fixed;
    padding: 0 2em;
    height: 80dvh;
    z-index: 10;
    transform: translateY(150%);
    transition: all 0.5s ease;
    border-top-right-radius: 2em;
    border-top-left-radius: 2em;
    box-shadow: 0px 0px 10px 0px #00000039;
    left: 0;
    font-size: $font-size-little;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 2em;
    bottom: 0;
    color: $black !important;
    background: #FFF7B5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.drawerSpeedChallenge.open{
        transform: translateY(0);
    }
    .closeArrow{
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 1em;
        right: 1em;
        img{
            width: 40px;
            height: 40px;
        }
    }
    .text{
        font-size: 12px;
        text-rendering: geometricPrecision;
        line-height: 1.5em;
        font-family: 'poppinsregular';
        font-weight: 16px;
    }
    .descriptionSpeed{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        justify-content: center;
        width: 100%;

        .titre{
            font-size: $font-size-medium;
            font-family: 'geomanistbold';
            font-weight: bold;
        }
    }

    .ProbabiliteSpeed{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        justify-content: center;
        width: 100%;
        .lot_text_group{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;}
        .p_text_probabilite{
            font-size: $font-size-little;
            font-family: 'geomanistbold';
            font-weight: 400;
          }
          .lot_text_group{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: revert-layer;


            }
            .valeur_gift{
                font-size: $font-size-little;
                font-family: 'geomanistbold';
                font-weight: bold;
            }
        .img_text_group{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
        }
        .TitreProbabiliteSpeed{
            font-size: $font-size-medium;
            font-family: 'geomanistbold';
            font-weight: bold;

        }
        .lot{
            display: flex;
            gap: 1em;
            justify-content: center;
            align-items: center;
            width: 100%;

            .lucky_box{

                font-weight: 900;
                display: flex;
                flex-direction: column;
                gap: 1em;
                font-size: $font-size-little;
                font-family: 'geomanistlight';
                font-weight: 600;
                margin-left: auto;
                justify-content: space-between;
                align-items: center;

                .number{
                    background: #0E0B26;
                    display: flex;
                    justify-content: center;
                    gap: 0.5em;
                    color: white;
                    border-radius: 0.5em;
                    padding: 0.3em;
                    font-size: $font-size-medium;
                    font-family: 'geomanistbold';
                    text-align: center;
                    width: 100%;

                    img{
                        width: 20px;

                    }
                }
               }
        }

        .lot3{
            display: flex;
            gap: 1em;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;

        }
    }

    .how_to_play_container{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1em;
        width: 100%;
        margin-bottom: 50px;


        .intro-text{
            width: 100%;
            text-align: left;
            font-size: $font-size-little;
            font-family: 'poppinsregular';
        }
        .titre{
            font-size: $font-size-medium;
            font-family: 'geomanistbold';
            font-weight: bold;
            width: 100%;
            text-align: left;

        }
       .text{
        li{
            list-style:disc;
            margin-left: 2em;
            margin: 0;

        }
       }

       .li{
        display: flex;
        gap: 0.5em;
        align-items: flex-start;
        justify-content: flex-start;

       }
    }

    .confidentialite{
        text-align: left;
        font-family: 'geomanistregular';
        font-weight: normal;
        font-size: $font-size-little;

        margin-bottom: 50px;
        a{
            color: $black;
            text-decoration: underline;
            font-family: 'geomanistbold';
        }
    }
}