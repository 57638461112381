@import '../../variables';

#user-fidelity-card {
  height: 100%;

  .user-fidelity-card-body {
    height: calc(100% - $height-scan-footer);
    padding: $spacer-6;
    overflow-y: auto;

    .user-fidelity-card-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .user-fidelity-card-back {
        margin-bottom: 10px;
      }

      .user-fidelity-card-title {
        width: 100%;
        text-align: center;
        margin: $spacer-5 0;
        font-size: $font-size-huge;
        font-family: 'geomanistmedium';
      }

      .user-fidelity-card-tile-container {
        display: flex;
        gap: $spacer-4;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;

        .fidelity-card-tile {
          display: flex;
          width: 160px;
          height: 100px;
          border: 1px solid lightgrey;
          border-radius: 10px;
          margin-top: 5px;
          justify-content: center;

          .add-card-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $spacer-2;
            font-family: 'geomanistmedium';
            font-size: $font-size-normal;

            .add-card-img {
              width: 35px;
            }
          }

          .fidelity-card-tile-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.fid-card-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .fid-card-slider-title {
    font-size: 24px;
    font-family: 'geomanistmedium';
    padding: $spacer-3;
    margin-bottom: $spacer-3;
  }

  .fid-card-slider-content {
    display: flex;
    width: 100%;
  }
}
