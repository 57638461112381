.rowcart{
    min-height: 100%;
    min-width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 1fr;
    padding-left: 2%;
    padding-right: 2%;
    position: relative;
    margin-bottom: 10rem;
    top: 20%;
    
}
.logopineapanier{

    display: flex;
    /* justify-content: center; */

}
#pineapanier_logo{
    position: absolute;
    width: 27px;
    height: 42px;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%,0);
    top: 18%

}
#pineapanier_text{
    font-family: 'poppinsregular';
    position: absolute;
    /* right: -37.42%; */
    left: 50%;
    transform: translate(-50%);
    color: #303030;
    font-size: 20px;
    top: 30%;
}
.cartrightheader{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 30%;
    min-height: 100%;
    min-width: 100%;
    align-items: center;
    position: absolute;
    z-index: 0;
    /* justify-content: center; */
}
.brand{
    position: relative;
    display: flex;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    width: 100%;
    height: 50px;
}
.leftheaderbackscan{
    z-index: 1;
}
.rightheaderorder{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    font-size: 18px;
    width: 100%;
    font-family: 'poppinsregular';
}

