@import '../../variables';

.cartscreen {
    background-color: #ffffff;
}

.promoscreen{
    /* padding: 3em; */
    width: 100%;
    padding-bottom: 60px;

    
    &.bottom-padding{
        padding-bottom: 0;
    }
}
.passezAuPayement{
    position: fixed;
    bottom: 20%;
    left: 50%;
    width: 100%;
    font-size: 20px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    transition: all 0.3s ease-in-out;
}
.pasDePromoText p{
    font-size: 1.7rem;

    font-weight: 600;
    font-family: "openSansItalic";

    margin: 0;
    color:rgba(0, 0, 0, 0.555);
    margin-top: 2em;
    text-align: center;

    border-radius: 0.5em;
    padding: 1em;
}
.pasDePromoText{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em;
    height: 50%;
}
.promoscreen_loading{
  animation: animation_opacity 1s ease-in-out infinite alternate;
  pointer-events: none;
  transition: all 0.2s;

}
.mailformpayment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2em;
    width: 98%;
    border-radius: 0.8em;
    &.margin-top{
        margin-top: 2em;
    }
 

    label{
        width: 100%;
        font-size: 14px;
        font-family: 'geomanistbold';
        text-align: left;
    }
}
.big_text{
    font-size:calc($font-size-larger + 0.1em);
}
.mailformpayment input[type="text"]{
    width: 100%;
    padding: 1.3em;
    border-radius: 0.8em;
    outline: none;
    font-size: 14px;
    background-color:#ffffff ;
    height: 45px;
    padding-left: 1em;
    border: solid 1px #00000038;
    box-shadow: inset 0px 0px 0px rgb(195, 226, 16);
    transition: all 0.2s ease-in-out;


}
.mailformpayment input[type="text"]:focus{
    background-color: white;
    border:  solid 1px #00000038;
    box-shadow: 0px 0px 0px 5px #f1e7278c;
    padding-left: 1em;
}

/*-----------------------------price------------------------------------*/
.price{
    display: flex;
    justify-content: flex-end;
}
.apparition{
    opacity: 0;
    animation: animation_opacity 0.6s ease-in-out forwards;


}
@keyframes animation_opacity{
    0%{
        opacity: 0.1;
    }
    100%{
        opacity: 1;
    }
}
.price_animation{
    animation: price_animation 1s ease-in-out forwards;
    margin-right: 10px;
}
@keyframes price_animation{
    0%{
        scale: 0.5;
    }
    90%{
        scale: 1.1;
    }
    100%{
        scale: 1;
    }
}
@keyframes animation_opacity2{
    0%{
        opacity: 0;
        transform: translateX(-10px);
    }
    90%{
        opacity: 0;
        transform: translateX(-10px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}
/*-----------------------------price------------------------------------*/

.promoscreen_button{
   width: 100px !important;

    font-size: 18px;
    font-weight: 500;
    outline: none;
    border: none;
    width: auto;
    text-align: center;
    border-radius: 0.6em;
    transition: all 0.2s ease-in-out;
    background: linear-gradient(100deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);

}
.desactiverpromo{
    outline: none;
    border: none;
    font-size: 18px;
}
.promoscreen_usee{
    font-size: 16px;
    font-weight: 600;
    width: 80px;
    text-align: center;
}

.conditionDutilisation{
    display: flex;

    justify-content: flex-start;
    align-items: center;
    background: #ffffff;

    gap: 1em;
    margin-bottom: 1em;

    font-size: 12px;
    width: 95%;
    flex-direction: row;
}
.conditionDutilisation input[type="checkbox"]{
    width: 0px;
    opacity: 0.1;
   height: 3px;
    border-radius: 1em;
    outline: none;

    cursor: pointer;
}
.vibrationred{
    animation: vibrationshadowred 0.5s ease-in-out;
}
@keyframes vibrationshadowred{
    0%{
        box-shadow: 0px 0px  0px rgba(255, 0, 0, 0.5);
        transform: translateY(0px);
    }
    25%{
        box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5);
        transform: translateY(5px);
    }
    50%{
        box-shadow: 0px 0px  0px rgba(255, 0, 0, 0.5);
        transform: translateY(0px);
    }
    75%{
        box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5);
        transform: translateY(-5px);
    }
    100%{
        box-shadow: 0px 0px 0px rgba(255, 0, 0, 0.5);
        transform: translateY(0px);
    }
}

.price_slashed{
    color: #00000073;
    font-size: 14px;
}
.price_slashed::after{
    content: "";
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translateY(-50%)  rotate(5deg);
    width: 120%;
    height: 2px;
    background: #e01313;
}
.div_promo{
    width: 100%;
}
.slideuppopupY{

    animation: slideuppopupY 0.5s ease-in-out forwards;

}

.box_promo_etape{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    width: 95px;
    height: 80px;

    position: relative;



    border-radius: 1.2em;
}
.cercle_promo_etape{
        position: absolute;
        width: 35px;
        height: 30px;
        border-radius: 50%;
        bottom: 80px;
        background: white;
        border: solid 1px rgba(0, 0, 0, 0.212);
}
.indisponible{
  filter: grayscale(100%);
}
.box_promo_etape.active{
    background: linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF);
    color: #242424;
    padding: 0;
    animation: apparition 0.5s ease-in-out;
}
.jigglescale{
    animation: jigglescale 1.2s ease-in-out infinite;
}
.coupons{
  width: 100%;
  display: flex;
  border: solid 1px rgba(0, 0, 0, 0.116);
  padding: 0.5em 1em;
  border-radius: 1em;
  justify-content: space-between;
  background: #f8f8f8;
  align-items: center;
  gap: 1em;
  position: relative;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.123);

}
.coupons2{
    width: 100%;
    display: flex;

    padding: 0.5em 1.5em;
    border-radius: 0.5em;
    justify-content: space-between;
    background: #f0f0f0;
    align-items: center;
    position: relative;

}
.coupons.active{
    background: linear-gradient(100deg, rgba(225, 255, 38, 1) 15%, rgb(255, 241, 38) 100%);
}
/* .coupons::after{
    content: "";
    position: absolute;
    top: 50%;
    left: -16px;
    border-right: solid 1px rgba(0, 0, 0, 0.233);
    transform: translateY(-50%);
    width: 35px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: inset -1px 0px 2px rgba(0, 0, 0, 0.123);

} */
.coupons2::after{
    content: "";
    position: absolute;
    top: 50%;
    left: -16px;

    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);


}
/* .coupons::before{
    content: "";
    position: absolute;
    top: 50%;
    right: -16px;
    border-left: solid 1px rgba(0, 0, 0, 0.233);
    transform: translateY(-50%);
    width: 35px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: inset 1px 0px 2px rgba(0, 0, 0, 0.123);

} */
.coupons2::before{
    content: "";
    position: absolute;
    top: 50%;
    right: -16px;

    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);


}
@keyframes jigglescale{
    0%{
        transform: scale(1) rotate(0deg);
        box-shadow: 0px 0px 0px rgba(143, 255, 14, 0.932);
    }
    30%{
        transform: scale(1.05) rotate(1deg);
        box-shadow: 0px 0px 10px rgb(184, 204, 2);
    }
    70%{
        transform: scale(1) rotate(-1deg);
    }
    100%{
        transform: scale(1) rotate(0deg);
    }
}
.payer.active{
    background: linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF);
}
.box_promo_etape.indisponible .promoscreen_button{
    background: #b8b6b6;
    font-size: 14px;
    color: #2c2c2c;
    user-select: none;
    pointer-events: none;

}


.nonmerci{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    bottom: 20%;
  }
.loader_popup{

  display: none;
  justify-content: center;
    align-items: center;


}
.contenaire_promo_carte{
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100dvw;
    height: 100%;
    background: #000000b0;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
.carte_promo{

   position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5em;
    width: 90%;
    border-radius: 2em;
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    gap: 2em;
    padding-top: 5em;

}
.barrepikko,.barrepikkotrue{
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    height: 101%;
    transition: all 1s ease-in-out;
}
.price_fond{
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 0;
    font-size: 12rem;
    font-weight: 900;
    opacity: 0.2;

}
.carte_promo h2{
    font-size: 1.8rem;
    font-weight: 600;
    color: #000000;
    font-family: "poppinsregular";
    margin: 0;

}
.carte_promo p{
    margin-bottom: 2em;
}
.btn_promo_carte{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "intersemibold";
    width: 100%;


}
.conditionsDutilisation{
    font-size: 16px;
}
.payer_container{
    width: 100%;
    position: fixed;
    bottom: 2em;

    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #353535;
    gap: 8px;
    transform: translateX(-50%);
    left: 50%;
}

.barrepikko{
    width: 0;
    animation: widthbarrepikko 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
.barrepikkotrue{

}
@keyframes widthbarrepikko{
    0%{
        width: 0;
    }
    100%{
        width: 85%;
    }
}

.payer{
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 251, 38) );
    border-radius: 1em;
    display: flex;
    align-items: center;
    font-family: 'geomanistmedium';
    color: #353535;
    justify-content: center;

    &.notif{
        border-radius: 0.5em;
    }
}

.btn_promo_carte button{
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 1em;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: #000000;
    font-family: "poppinsregular";
    margin-bottom: 1em;
    cursor: pointer;

}
.btn_promo_carte .non{
    background: #ffffff;
    margin-right: 1em;
}
.btn_promo_carte .oui{
    background: #000000;
    color: #ffffff;

}
.tremble{
    animation: tremble 0.7s infinite linear ;

}

.cubicbezier{
    transition: all 2000ms cubic-bezier(.47,1.64,.41,.8) ;
}

@keyframes tremble {
    0% { transform: rotate(0deg) scale(1);
        box-shadow: 0px 0px 10px 10px rgba(255, 227, 16, 0);
     }
    25% { transform: rotate(1deg); box-shadow: 0px 0px 10px 10px rgba(255, 227, 16, 0); }
    50% { transform: rotate(0deg) scale(1.01); box-shadow: 0px 0px 5px 10px rgba(255, 227, 16, 0.479); }
    75% { transform: rotate(-1deg);  box-shadow: 0px 0px 10px 10px rgba(255, 227, 16, 0.591);}
    100% { transform: rotate(0deg) scale(1); box-shadow: 0px 0px 10px 10px rgba(255, 227, 16, 0); }
  }
.pt_email{
    font-size: 12px;
    font-weight: 300;
}
.titre_popup_grey{
    position: relative;
    font-size: 16px;
    color: #0000005e;
    font-weight: 600;
    font-family: 'poppinsregular';
    display: flex;
    flex-direction: row;
    align-items: center;
}

@keyframes loader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.nav-arrow-r {
    transition: transform 0.5s ease;
}

.rotate-90 {
    transform: rotate(90deg);
}

.toggle-btn {
    display: none;

}

.p2em{
    padding: 2em;
}

div .toggle-label {
    position: relative;
    cursor: pointer;
    display: block;
    width: 42px;
    height: 20px;
    background: #cccccc;
    border-radius: 50px;
    transition: 300ms linear;
}

div .toggle-label:before {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background: #ffffff;
    border-radius: 50%;
    transition: left 300ms linear;
}

div .toggle-btn:checked+label {
    background: #000000;
}

div .toggle-btn:checked+label:before {
    left: 23px;
}

.close_cb{
    position: absolute;
    top: 20%;
    right: 6%;
    font-size: 1.5rem;
    display: none;
    color: #0000009c;
    cursor: pointer;


}
.close_cb_visible{
    display: flex;
    animation: close_apparition 0.5s ease-in-out;
    z-index: 10;
    background: linear-gradient(100deg, #E1FF26 0%, #FFCF26 100%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 400;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.185);

}

.closepopup{
    background: rgb(239, 239, 239);
    border-radius: 50%;
    padding: 0.5em;
    width: 50px;
    height:50px;
    position: absolute;
    left: 1em;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.closepopup2{
    background: rgb(239, 239, 239);
    border-radius: 50%;
    padding: 0.5em;
    width: 40px;
    height:40px;
    position: absolute;
    left: 1em;
    //z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.closepopup img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    object-fit: contain;
    position: relative;
    bottom: 1px;
}
.contenaire_icon_visa_mastercard{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 0.5em 0.5em 0;
    justify-content: flex-start;
    position: relative;
}


.grey-bg {
    background-color: rgb(248 250 252);
}

.slide-down-btn {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* background-color: rgb(248 250 252); */

}

/* .slide-down-transition {
    transition: height 1.3s ease-in-out;
    transition: all .5s ease-in-out;
} */

.slide-down-content{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-top: none;
    border-bottom: solid 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s linear;
  

    &.hidden {
        visibility: hidden;
    }
}

.slide-down-content.visible {
    height: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-top: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Set height to auto to reveal the content */
}






.edenred_visible {
    height: auto;
    position: relative;
    z-index: 6;
    display: grid;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation: apparition 0.6s cubic-bezier(0.375, 0.885, 0.32, 1.275);
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    top: 80%;
    /* Set height to auto to reveal the content */
}
/* .edenred_visible::after{
    content: 'OU';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    color: #ffffff;
    font-family: "poppinsregular";
    font-weight: 600;
} */


.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}



/* CrÃ©ez une case Ã  cocher ronde en utilisant un pseudo-Ã©lÃ©ment ::before */
.custom-checkbox .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* pour crÃ©er une forme ronde */
    border: 1px solid black;
    /* bordure noire autour de la case Ã  cocher */
}

/* Style du pseudo-Ã©lÃ©ment ::before lorsque la case est cochÃ©e */

.custom-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: black;
}


.custom-checkbox input[type="checkbox"]:checked+.checkmark::before {
    content: "\2713";
    /* symbole de coche unicode */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.shadow-none {
    box-shadow: none;
}

.edenred-btn {
    /* border: solid linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%); */
    font-family: "intermedium";
    font-size: 1.875rem;
    border: none;
    /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12); */
}

.edenred-btn-active {
    border: solid 0.5px rgb(226 232 240);
    font-size: 1.65rem;

}


.smallimg {
    height: 75px;
    border-radius: 50%;
    /* border: 4px solid #303030;*/
}

.cart_list {
    overflow: hidden;
    border-bottom: solid 1px rgba(73, 80, 61, 0.281);
}

.cart-item-price {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    font-family: 'geomanistBold';
    width: fit-content;

    .earn-point-container {
        position: absolute;
        display: flex;
        align-items: center;
        transform: translateX(100%);
        right: -$spacer-3;
        font-size: 14px;

        .earn-point {
            color: $green;
        }

        .coin-img {
            width: 14px;
            margin-left: 2px;
            margin-bottom: 3px;
        }
    }
}

.productimage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.productdescription {
    padding-right: 1rem;
    color: black;
    font-family: 'poppinsregular';
    /* font-family: 'Nunito', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 20px; */
    /* margin-top: 23px;
    margin-bottom: 14px; */
    text-align: center;

}

#conecspopup::after{
    content: "Titre restaurant";
    font-family: 'poppinssemibold';
    position: absolute;
    top:-2em;
    margin: 0.5em;
    font-size: 24px;
    color: #fcfcfc;
    font-weight: 600;
    letter-spacing: -1px;
}
.formule {
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
}

.min-30 {
    /* font-size: 18px;
    margin-top: 5px; */
    color: #767676
}

.min-30_price {
    font-family: "intersemibold";
    border-width: 0.5px;
    border-color: #767676;
}
.containaire_ajout_retrait{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}

.productdelete {
    display: flex;
    padding: 1rem;
    background: linear-gradient(96.63deg, #FF627F 13.87%, #FFBA88 87.26%), #FF9494;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 60%;
}

#buttondelete {
    border: none;
    font-size: 2rem;
    padding-left: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#deletepayment{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100dvw;
    height: 100dvh;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

#remove {
    width: 110%;
}

.dashed-top {
    border-top: 1px dashed rgba(0, 0, 0, .16);
}

.add_bag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Source Sans Pro', Helvetica, serif;
    font-size: 13px;
    width: 85%;
    padding-bottom: 1rem;
    gap: 10px
}

.promotion_button {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
}

#promo_input {
    border: none;
    outline: none;
    font-family: 'Source Sans Pro', Helvetica, serif;
    /* font-family: 'Nunito', sans-serif; */
    font-style: normal;
    font-weight: 400;
    background-color: transparent;
    width: 100%;
}

#promo_button {
    background: linear-gradient(104.44deg, rgba(0, 99, 164, 0.38) 108.22%, #E1FBF1 124.03%);
    ;
    color: rgba(0, 52, 86, 0.75);
    border: none;
    padding: 1rem;
    border-radius: 10px;
    font-family: 'source_sans_prosemibold';
    /* font-family: 'Nunito', sans-serif; */
    font-style: normal;
    font-weight: 500;
}

.subtotal {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    font-family: 'Source Sans Pro', Helvetica, serif;
    /* font-family: 'Nunito', sans-serif; */
    font-weight: 300;
    font-size: 13px;
    color: black;
}

.promotion {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    font-family: 'Source Sans Pro', Helvetica, serif;
    font-weight: 300;
    font-size: 13px;
    color: black;
}


/* .recap_cart2_price_ {
    display: flex;
    font-family: "interbold"
}

.recap_cart2_promo {
    font-weight: 800;
    font-size: 18px;
    color: black;
    text-decoration-line: none;
} */

.payment_cart {
    display: flex;
    width: 112%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.payment_button {
    background-color: black;
    height: 58px;
    border-radius: 59px;
    border: none;
    padding: 10px;
    width: 90%;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.103);
}

.proceder_paiement {
    font-family: 'Source Sans Pro', Helvetica, serif;
    /* font-family: 'Nunito', sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: white;

}

.content_logopromo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;


}
.contenaire_promobox p{
    font-size: 16px;
    font-weight: 600;
}
.button_toogle_promo {
    position: relative;
    padding-left: 0.2em;
    padding-right: 0.2em ;
    display: flex;
    align-items: center;
    width: 55%;
    height: 40px ;
    transition: all 0.3s ease-in-out;

}

.text_code_promo{
    font-size: 14px;
    width: 70%;
    transition: all 0.3s ease-in-out;
    margin: 0.3em;
    border: none;
    outline: none;
    height: 100%;
    padding-left: 6%;

}
.text_code_promo:focus{
    outline: none;
    border: none;
}
.contenaire_promobox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    overflow: hidden;
    gap: 1em;
    padding: 1.5em;
    margin-bottom: 2em;
    border-top: solid 1px rgba(212, 212, 212, 0.562);
    border-bottom: solid 1px rgba(212, 212, 212, 0.562);

}
.contenaire_promobox h2{
    font-size: 16px;
    font-weight: 900;
}

.header_cart{
    /* border-bottom: dashed 1px rgba(0, 0, 0, .16); */
    padding-bottom: 2rem;

}

.button_promo {
    position: absolute;
    border: none;
    outline: none;
    overflow: hidden;
    right: 0.5rem;
    top:50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
}

.text_code_promo:focus {
   padding: 0.3em;
}
.toogle_promo_active{
    background: linear-gradient(#e2ff2642,#ffd02634);
}

.promo_active_input{
    transform: translateX(30px);
    overflow: hidden;
    background: transparent;
    border: none;
    outline: none;
}

.Alert_response_background{
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    /* display: none; */
    background: rgba(253, 193, 193, 0.247);
    z-index: 4;
}

.Alert_response{
    position: absolute;
   top: 50%;
   font-size: 1.5rem;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70%;
    height: 10%;
    padding: 1rem;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: apparition 0.2s ease-in-out;
}
.Alert_response button{
    position: absolute;
    bottom: -1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 50%;
    height: 20px;
    border-radius: 2em;
    border: none;
    outline: none;
    background: #e94949;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.301);
    font-size: 16px;
    font-weight: 600;
    color: #000;
    cursor: pointer;

}
.Alert_response_background .succes{
    background: rgba(193, 253, 193, 0.151);
}
.Alert_response_background  button .succes{
    background: #beec17;
}
.vibration{
    animation: vibration 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
@media screen and (max-width:400px){
    .contenaire_promobox p{
        font-size: 14px;

    }
}
@keyframes vibration{
    0%{
        transform: translateX(0px);
    }
    25%{
        transform: translateX(5px);
    }
    50%{
        transform: translateX(0px);
    }
    75%{
        transform: translateX(-5px);
    }
    100%{
        transform: translateX(0px);
    }

}

.masked_popup{
    transform: translateY(100%);
}
.Label_formulaire{
    background-color: white;
    font-size: 14px;
    padding: 0.5em;
    border-radius: 0.5em;
    font-weight: 100;
    margin-left: 0.5rem;
    position: relative;
    top: 0.5em;
}
.double_input_zone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 2em 2em 0 2em;
    margin-bottom: 1em;
}
.paymentPopup{
    background: #ffffff ;
    transition: all 0.7s cubic-bezier(0.375, 0.885, 0.32, 1.075);
    width: 100%;
    height: 100%;
    border-top-right-radius: 4em;
    border-top-left-radius: 4em;
    padding: 2em;
    padding-top: 0;
}

[data-rsbs-footer] {
    box-shadow: none !important;
    padding-right: 2em;
    padding-left: 2em;
}

.payment-error {
    text-align: center;
    color: $red;
    font-size: $font-size-normal;
    margin: $spacer-4 0;

    &.no-bottom {
        margin-bottom: 0;
    }

    &.footer{
        margin-top: 0;
    }

    &.full {
        width: 100%;
    }
}

.view_popup{

    -webkit-transform: translate(-50%,0%);
    transform: translate(-50%,0%);

    transition: all 0.7s cubic-bezier(0.375, 0.885, 0.32, 1.075);
}
@keyframes backblur{
    0%{
        box-shadow: 0px 0 0 1000px  #00000000;
    }
    100%{
        box-shadow: 0px 0 0 1000px rgba(0, 0, 0, 0.815) ;
    }
}
.view{
    display: flex;
}
.moitier_du_formulaire{
    width:50%;

    display: flex;
    flex-direction: column;
}
.accordion {
    position: relative;
    color: #444;
    cursor: pointer;
    border-radius: 1em;
    font-size: 15px;
    transition: 0.4s;
    height: 50px;
    border: solid 1px rgba(221, 192, 24, 0);
    display: flex;
    align-items: center;
    z-index: 2;
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;

     padding: 2em;
    background: rgba(202, 202, 192, 0.301);
    border-radius: 1em;

    transition: all 0.5s ease-in-out;

  }
#contenaire_mail{
    position: relative;
    margin-top: 3em;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.contenaire_mail_promo{
    position: absolute;
    font-family:'Source Sans Pro', Helvetica, serif ;
    top: 20%;
    left: 50%;
    z-index: 45;
    transform: translate(-50%,-50%);
}
.contenaire_mail_promo input{
    font-family: 'Source Sans Pro', Helvetica, serif;
}
.blur{
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100dvw;
    background:rgb(83 79 60 / 54%);
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    height: 100dvh;
    transition: opacity 0.3s ease-in-out;
}
.blur_cb{
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100dvw;
    background:rgb(83 79 60 / 54%);
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    height: 100dvh;
    transition: opacity 0.3s ease-in-out;
}
.accordion .user_mail_input{
    width: 100%;
    font-size: small;
}
.label_view{
    color: white;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-family: "poppinsregular";

}



.check_circle{

    animation: check_apparition 0.3s  cubic-bezier(0.445, 0.05, 0.55, 0.95);

}
#cbpopup::after{
    content: "";
    font-family: 'poppinssemibold';
    position: absolute;
    top:-2em;
    margin: 0.5em;
    font-size: 24px;
    color: #fcfcfc;
    font-weight: 600;
    letter-spacing: -1px;

}

.boutton_payer{
    background: rgb(226 247 107);
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.103);
    padding: 2em;
    border-radius: 1em;

    bottom: 20%;
    width: 100%;
}

.payer_maintenant_btn {
    justify-content: center;
    gap: 3em;
    padding: 1em;
    margin-top: 1em;
    border-radius: 0.8em;
    margin-bottom: 1em;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 16px;
    border: none;
    font-weight: 700;
    overflow: hidden;
    background: rgb(225, 255, 38);
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    font-family: 'Open Sans';

    &.error-message {
        margin-top: 0;
    }
}

.payer_maintenant_btn.disable{
    background: #8f8f8b6e;
    color: #a8a8a84d;
    /* opacity: 0.1; */
    pointer-events: none;
}
.payer_maintenant_btn p{
    font-size: 20px;
}

.ajouter_payment_btn{

  display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: fit-content;



}
.btn_disable{
    background: #d3d3d3;
    color: #ffffff;

    /* opacity: 0.1; */

}
.shadow{
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.192);
    border-radius: 1em;
}
.border{

    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
}


.panel {
    position: relative;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.164);
    border-radius:2em ;
    margin: 0px 0px 2em 0px;
    background-color: white;
    overflow: hidden;
    z-index: 1;
    transition: max-height 0.3s ease-out;
  }



  .suivant_btn{
    display: none;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  }
  .pikkopay_logo{
    height: 10rem;
}
  .suivant_btn_view{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 134px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.205);
    min-width: 100px;
    position: absolute;
    bottom: -20px;
    right: 0em;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 6;
    animation: delay_apparition 1.5s ease-in-out;
  }
  .suivant_btn_view:disabled{
    background: #dceb0b;
    color: #a8a8a8;
    /* opacity: 0.1; */
    display: none;
  }
  .blur_visible{
    opacity: 1;
    pointer-events: all;
}
.focus_zone{
    z-index: 5;
    padding: 1.5em;

}
.focus_zone_cb{
    z-index: 5;
    padding: 1.5em;
    max-height: 100%;

}

.checked_svg{
  box-shadow: inset 0 0 0 1px #00000033;
border:none;
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    animation: jelly 0.6s ease;


  }
@keyframes check_apparition{
    0%{
        opacity: 0;
        transform: scale(0.5);
    }
   50%{
        opacity: 1;
        transform: scale(1.5);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes close_apparition{
    0%{
        opacity: 0;
        transform: scale(0.5)   translate(50%,50%) perspective(200px) rotate(0deg);;
    }
    100%{
        opacity: 1;
        transform: scale(1) translate(0%,0%) rotate(0deg);;
    }
}
@keyframes apparition{
    0%{
        opacity: 0;
        scale: 0.5;
    }
    100%{
        opacity: 1;
        scale: 1;
    }
}
@keyframes delay_apparition{
    0%{
        opacity: 0;

    }
    80%{
        opacity: 0;

    }
    100%{
        opacity: 1;

    }
}
@media screen and (max-width:500px){
    .close_cb_visible{
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
    .cbpopup::after{
        font-size: 15px;
        margin: 0.5em;
    }

}




  .button {
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #0d6efd;
    border-color: #0d6efd;
    width: 33%;

  }

  .button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

#cbcheckbox{
    height: 1px;
    width: 1px;
    opacity: 0.1;
}


/*

  @media screen  and (max-height:800px) {
    .edenred_visible{
        bottom: 0;
    }
    .edenred_visible::after{
        content: 'ou';
        position: absolute;
        top: 50%;
        left: -1em;
        transform: translate(-50%);
        font-size: 20px;
        color: #ffffff;
    }
} */
@media screen  and (max-height:700px) {
    .edenred_btn{
        top: 6rem;
    }
}

  .checkout_contenair{
    margin: 10px;
    background-color: #fff;
    border-radius: 1em;
    font-size: 14px;
    border: solid 1px rgba(0, 0, 0, 0.055);
    overflow: hidden;
  }
 .checkoutsummary{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;


  }
  .checkoutcartitems{
    display: flex;
    flex-direction: row;
    box-shadow: none !important;
    margin: 1em;
    justify-content: space-between;
    align-items: center;
    border:none ;
    padding: 0.5em;
  }
  /* summary::-webkit-details-marker{
    font-weight: bold;
    display: none;
    font-size: 2em;
    margin-right: 0.5em;
  } */

  .checkout_contenair table {
    width: 100%;
    border-collapse: collapse;
  }

  .checkout_contenair  th, td {
    border: none;
    padding: 10px;
    text-align: left;

  }
  .checkout_contenair .td_int{
    text-align: right;
    display: flex;
    opacity: 0.60;
    flex-direction: row;

    &.td_int_visible{
        opacity: 1;
    }
}
.itemcartpricesmall{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.checkout_contenair tbody{
    border-top: solid 1px rgba(0, 0, 0, 0.123);
    border-bottom: solid 1px rgb(0 0 0 / 7%);
  }
  .checkout_contenair thead{
    width: 100%;

  }
  .checkout_contenair tr{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* th {
    background-color: #ffffff;


  } */
  td{
    font-weight: 600;
  }
  tfoot {
    font-weight: bold;
  }
  /* checkbox animation */
  .cbx{
    position: relative;
    top: 1px;
    width: 24px;
    transform: translateY(-2px);
    height: 24px;
    border: 1px solid black;
    background: white;
    border-radius: 8px;
    transition:  0.1s ease;
    cursor: pointer;
    display: block;

   }

   .cbchecklabel,.paygreenlabel,.conecslabel,.edenredlabel{
    position: relative;
    top: 1px;
    width: 24px;
    transform: translateY(-2px);
    height: 24px;
    border: 1px solid black;
    background: white;
    border-radius: 100px;
    transition:  0.1s ease;
    cursor: pointer;
    display: block;
   }
   .disabled_button{
       pointer-events: none;
       filter: grayscale(1);
       background-color: #cecece;
       border-radius: 0.5em;
   }

   .formcarte{
        margin-top: 1.5em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 2em;
        height: 68px;
        background: rgb(255, 255, 255);
        border: solid 1px rgba(0, 0, 0, 0.075);
        border-radius: 1em;
   }
   .formcarte.open{
    background: none;
    border-top: solid 1px rgba(0, 0, 0, 0.1215686275);
    border-right: solid 1px rgba(0, 0, 0, 0.123);
    padding-bottom: 0;
    padding-top: 2em;
    height: 30px;
    border-bottom: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.123);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
   }
   .explication-fin{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .explication-fin-liste{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        text-align: center;
        background: white;
        font-family: 'geomanistmedium';
        padding: 1.5em;
        color: #3523B9;
        border-radius: 1em;
        width: 100%;

    }
   }
   .animNumber{
     position: relative;
     animation: animNumber 0.3s ease 1s forwards;
     transform: translateY(100%);
    }
   @keyframes animNumber{
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
    }
   }
   .cbx:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
    width: 7px;
    height: 14px;
  opacity: 1;

    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 2;

   }
   .paygreenlabelenregistrer,.checkbox{
    position:relative !important;
   }
   label.cbchecklabel::after,.conecslabel::after,.paygreenlabel::after,.choicecb:checked ~ .cbchecklabel::after,.conecsCheckbox:checked,.paygreenCheckbox:checked ~ .conecslabel::after,#edenredcheck:checked ~ .edenredlabel::after,.checkbox::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 14px;
    transform: rotate(45deg) translate(-100%,-28%) ;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
   }
   .lbl {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
   }
   .paygreenlabelenregistrer::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    width: 7px;
    height: 14px;
    opacity: 1;
    transform: rotate(45deg) translate(-100%,-28%) ;

    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;

   }


   .slider{
    z-index: 100;
   }

   #cbx:checked ~ .cbx {
    box-shadow: inset 0 0 0 2px #00000033;
    background: green;
    animation: jelly 0.6s ease;
   }


   #cbCheckbox:checked ~ .cbchecklabel {

     box-shadow: inset 0 0 0 1px #00000033;
     border:none;
    background: green;
    animation: jelly 0.6s ease;
   }
   #conecsCheckbox:checked ~ .conecslabel, #choicecb:checked ~ .cbchecklabel, #paygreenCheckbox:checked, #paygreenCheckboxenregistrer:checked ~ .paygreenlabelenregistrer {

    box-shadow: inset 0 0 0 1px #00000033;
    border:none;
    background: green;
    animation: jelly 0.6s ease;
   }
    #conecsCheckbox:checked ~ .conecslabel {

        box-shadow: inset 0 0 0 1px #00000033;
        border:none;
        background: green;
        animation: jelly 0.6s ease;
    }

    #edenredcheck:checked ~ .edenredlabel {

        box-shadow: inset 0 0 0 1px #00000033;
        border:none;
        background:green;
        animation: jelly 0.6s ease;
       }

    #paygreenCheckbox:checked ~ .paygreenlabel {
        box-shadow: inset 0 0 0 1px #00000033;
        border:none;
        background:green;
        animation: jelly 0.6s ease;
    }


    #paygreenCheckboxenregistrer:checked ~ .paygreenlabelenregistrer {
        box-shadow: inset 0 0 0 1px #00000033;
        border:none;
        background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
        animation: jelly 0.6s ease;
    }

   .jelly {
    animation: jelly 0.6s ease;
   }

   #cbCheckbox, #paygreenCheckbox,#choicecb,#conecsCheckbox,#edenredcheck{
    width: 1px;
    height: 1px;
   }
   .cbandlabel{
    display: flex;

   }



   @keyframes jelly {
    from {
     transform: scale(1, 1);
    }

    30% {
     transform: scale(1.25, 0.75);
    }

    40% {
     transform: scale(0.75, 1.25);
    }

    50% {
     transform: scale(1.15, 0.85);
    }

    65% {
     transform: scale(0.95, 1.05);
    }

    75% {
     transform: scale(1.05, 0.95);
    }

    to {
     transform: scale(1, 1);
    }
   }


/* #cbpopup,#paygreen-conecs{
    transition:  max-height 0.4s ease-out;
} */
   .slide-down-transition {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }



  .slide-down-content.visible {

    max-height: 450px; /* Adjust the maximum height based on your content */

  }

  .grey-bg {
    background-color: #ffffff; /* Adjust the background color as needed */
  }

  .checkbox_border {
    border: 1px solid #ccc;
    padding: 5px;
  }

  .checkbox,.paygreenlabelenregistrer {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 0.3em;
    border: 1px solid #ccc;
    background-color: #fff;
  }

  /* last step scan */

  .cartefinalscancheck{
    width: 100%;
    height: fit-content;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;

    background: #ffffff;
    border-radius: 1em;

  }
  .cartefinalscancheckcontenair{
    margin: 0 auto;
    width: 90%;
    height: 100%;
    max-height: 88dvh;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    z-index: 5;
    padding: 1.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,800px);
    transition: all 0.3s ease-in-out;
    border-radius: 1em;

  }
  .cartefinalscancheckcontenair.explication{
    width: 50%;
  }
  .explicationcheck{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 1em;
    color: white;
    font-weight: 600;
    width: 90%;
    border-radius: 0.7em;
    background: #e8e9e65f;
    border: solid 1px #ffffff;
    z-index: 1000;

  }
  .animbackshadow{
   animation: shadowscreen 0.3s ease-in-out forwards ;
}
  .cartefinalscancheck img{
    padding: 1em;

  }
  .text_scan_column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
  }
  .Rdv{
    font-size: 16px;
    color: #1a1a1a;
    text-align: start;

  }
  .cercle_invoice1,.cercle_invoice2{
    position: absolute;
    width: 35px;
    height: 30px;
    border-radius: 50%;
    bottom: 80px;

  }
  .cercle_invoice1{
    left: -18px;
    box-shadow:inset -10px 0px 10px 5px #e8e9e6;
  }
  .cercle_invoice2{
    right: -18px;
    box-shadow:inset 10px 0px 10px 5px #e8e9e6;
  }
  .line_invoice{
    position: absolute;
    width: 100%;
    height: 2px;
    background: #00000044;
   display: flex;
    justify-content: center;
    align-items: center;


    bottom: 55px;
  }
  .Rdv li{
    list-style-type:decimal;
    font-size: 'geomanistmedium';
    font-size: 18px;
    font-weight: 100;

  }
  .cercle{
    width: 90px;
    height: 85px;
    border-radius: 50%;
    border: solid 5px #e8e9e6;
    border-bottom: solid transparent 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #000000;
    font-weight: 600;
  }
  .importantpwa{
    font-size: 10px !important;
  }
  .popupcheckscan {
    height: 100dvh;
    z-index: 120;
    position: absolute;
    overflow-y: auto;
    bottom: 0;
    overflow: hidden;
    justify-content: space-around;
    left: 50%;
    transform: translateX( -50%);
    width: 100%;

    display: flex;
    gap: 1.8em;
    align-items: center;
    flex-direction: column;

    background: #ffffff;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: all 0.3s ease-in-out;
  }
  .popupcheckscan.down{
    transform: translate( -50%,100%);
  }
  .white_color{
    color: #ffffff;
  }
  .promo_scan_check_text{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    text-align: start;
    padding: 1em;
    font-family: "geomanistregular";
    color: #000000;
    font-weight: 600;
    width: 100%;
    font-size: 14px;
    border-radius: 1em;
    background: #E1F1D3;
    label{
        color: #77B668;
        font-size: 12px;
    }
    span{
        color: #77B668;
        margin-left: 4px;
        font-size: large;
    }
  }
  .popupfinalisation{
    padding: 2em;
    display: flex;


    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    border-radius: 1em;
    margin: 2em 0;
    gap: 1em;
    background: #ffffff;
    height: fit-content;
    overflow: hidden;
  }
  .scale2{
    transform: scale(1.1);
    border: solid 4px rgb(220 255 9) !important;
    z-index: 2;
  }
  .blockedSkin{
    filter: brightness(0.8);
    opacity: 0.8;
  }
  .popupviewscan{
    position: absolute;

    bottom: 100%;
    left: 50%;
    height: fit-content;
    transform: translate( -50%);
    border-top-right-radius: 2em;
    border-top-left-radius: 2em;
    width: 100%;
    animation: popupviewscan 0.6s ease-in-out forwards ;
  }

   .cgu{
    color: #3523B9;
    font-size: 12px;
    text-decoration: underline;
   }
  .popupfinalisation strong{
    font-size: 3rem;

    font-weight: 600;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;

  }
  .appear{
    animation: slideopacity 0.5s ease-in-out forwards;
  }
  @keyframes slideopacity{
    0%{
        opacity: 0;
        transform: translateX(-120%);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}
.opacityAnimation{
    animation: opacity 1s ease-in-out forwards;
}
@keyframes opacity{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
  .persovite {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    justify-content: center;
  }

  .popupcheckscan.explication{
    transform: translateY(100%) translateX(-50%);

  }
  .checkscanandtext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4em;
    text-align: center;

  }
  .coupefiletuto{
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
   border-radius: 20px;
   min-height: 400px;
    overflow: hidden;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.329);
  }
  .blurback{

    opacity: 0;
    pointer-events: none;
    position: fixed;

    width: 100dvw;
    background:rgba(0, 0, 0, 0.8);

    z-index: 124;
    height: 100dvh;
    transition: opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .blurback.active{
    opacity: 1;
    pointer-events: all;
}
  @keyframes popupviewscan{
    0%{
        bottom: -100%;

    }
    100%{
        bottom: 0;

    }
  }
  .slideuppopup{
    transform: translateY(100%);
    animation: slideuppopup 0.4s ease-in-out forwards ;
  }
  .orderscreenanim_popup{
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 80%);
    z-index: 125;
    overflow: hidden;
  }
.slideuppopupXY{
    transform: translate(-50%,100%);
    animation: slideuppopupXY 0.4s ease-in-out forwards ;
}
.appeary{
    animation: appeary 0.4s ease-in-out forwards ;
}
.bg-gradient2{
    background: linear-gradient(143deg, rgb(248 206 91) 13.87%, #ffa288 87.26%);
}
.nextstep{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    position: fixed;
    top: 0.5em;
    right:0.5em;
    padding: 1em;
    color: #000000;
    font-weight: 400;
    font-size: 12px;
    border-radius: 2em;
    background: #00000026;

}
.white_card{
    background: #ffffff;
    border-radius: 1.5em;
    width: 93%;
    margin: 1em 0;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    font-size: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.164);

    .card_text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .second_text{
        font-size: 14px;
        font-family: 'interlight';
        color: #000000;
    }
    #fidelity{
        font-size: 26px;
        color: #00D315;

        &::before{
            content: '+';
            font-size: 26px;
            color: #00D315;

        }

    }
    .coin{
        width: 30px;
        height: 30px;
    }
}
.bottom_btn_by_margin{
    margin-top: auto;
    width: 100%;
    margin-bottom: 2em;
}
.pikkoselect::after{
    content: "";
    position: absolute;
    bottom: -2px;
    height: 4px;
    width: 60px;
    background:linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%);
    left: 0;


  }
@keyframes appeary{
    0%{
        opacity: 0;
        transform: translateY(100%) translateX(-50%);
        z-index: 2;
    }
    100%{
        opacity: 1;
        transform: translateY(0) translateX(-50%);
        z-index: 2;

    }

}
@media screen and (max-height:450px) {
    .orderscreenanim_popup{
        height: 100%;
        justify-content: flex-start;
        overflow-y: auto;
    }
}
  @keyframes slideuppopup {
    0% {

      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes slideuppopupXY {
    0% {
      transform: translate(-50%,100%);
    }
    100% {
      transform: translate(-50%,0%);
    }
  }
  @keyframes shadowscreen{
    0%{
        transform: translate(-50%,200px);
        opacity: 0;

    }
    50%{
        opacity: 0.5;

    }
    100%{
        opacity: 1;
        transform: translate(-50%,-50%);

    }
}
.inputGroup {
    font-family: 'Segoe UI', sans-serif;
    position: relative;
    width: 100%;
      .promo_label{
        font-size: 1.5em;
        margin: 10px 0 5px 0;
      }
  }

  .inputGroup input {
    font-size: 1.5rem;
    padding: 0.8em;
    padding-left: 1em;
    outline: none;
    border: solid 1px #00000038;

    transition: all 0.2s;
    background-color: #ffffff;
    border-radius: 10px;
    height: 50px;
   width: 100%;
   font-family: 'geomanistregular';
  }
  .gradient-2{
    background: linear-gradient(140deg, #FFCD5F 15%,  #FF7977 100%);
  }
  .inputGroup input:focus{
    background-color: white;
    border:  solid 1px #00000038;
    box-shadow: 0px 0px 0px 5px #f1e72789;
    padding-left: 1.5em;
  }
  .title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
  }

input::placeholder{
    font-size: 1.4rem !important;
    font-weight: 200;
    color: #000000e3 !important;
    opacity: 0.5;
    font-family: 'poppinsregular';
}
.createaccount .groupinput{
    display: flex;
    flex-direction: column;
    width: 100%;


}




.backgray{
    filter: grayscale(100%) brightness(0.95) ;



}
.backgray2{
    filter: grayscale(100%) brightness(0.95) ;
    background: rgb(224, 224, 224) !important;


}

.backdrop-filter1{
    backdrop-filter: blur(2px) contrast(0.6);
    -webkit-backdrop-filter: blur(2px) contrast(0.6);
}

.promotion_sliced{
    font-size: $font-size-normal;
    margin-left: $spacer-4 ;
    text-decoration: line-through;
    color: #9C9C9C;
}

