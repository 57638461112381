.store_list_container_principale {
    width: 100%;
    height: 100dvh;
    font-family: 'geomanistregular';
    padding: 2em 2em 4em 2em;
    overflow-y: scroll;
    overflow: hidden;

        .store_list_title{
            font-size: 2em;
            font-family: 'geomanistmedium';
            width: 100%;
            text-align: center;
            padding: 1em;
        }

        .store_list_searchbar {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;
            padding: 0.4em 1em 0.4em 1em;
            background: white;
            border-radius: 10px;
            border: solid 1px #E4DCDC;

                .store_list_searchbar_input{
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    font-size: 1.3em;
                    font-family: 'geomanistregular';
                }
        }

        .store_list_tuile_children_container{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          margin: 0.5em;
          gap: 0.5em;
          padding: 0;
          transition: all 0.2s linear;

          &.children_active{
            max-height: 1000px;
          }

          &.children_hidden{
            max-height: 0;
            overflow: hidden;

          }
        }

        .store_list_container_secondary {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          padding-bottom: 100px;

          .app-version {
            margin-top: 10px;
          }
        }
}

.companies_page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(0%) ;
  z-index: 100;
  left: 0;
  top: 0;
  position: fixed;
  transition: all 0.3s linear;

  &.hidden_companies_page{
    transform: translateX(120%);
  }
}

@keyframes hiddener{
  0%{
    display: flex;
  }
  100%{
    display: none;
  }
}

