@import '../../variables';

.gift-card-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .slider-title {
        font-size: 24px;
        font-family: 'geomanistmedium';
        flex-direction: column;
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-family: 'geomanistregular';
            opacity: 0.6;
            font-size: 14px;
            text-align: center;
        }
    }

    .gift-card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
            width: 1;
            height: 0;
        }

        .gift-card-item {
            display: flex;
            flex-direction: column;
            width: 48%;

            .gift-img-container {
                height: 120px;
                margin-bottom: 5px;
                border: 1px solid lightgrey;
                border-radius: 10px;

                .gift-img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 10px;
                }
            }

            .brand-name {
                margin-bottom: 2px;
                font-size: 14px;
                font-weight: bold;
            }

            .gift-amount {
                margin-bottom: 5px;
                font-size: $font-size-normal;
                font-weight: bold;
                color: $green;
            }
        }
    }
}

.gift-card-detail-slider {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    height: 75%;
    width: 100%;
    z-index: 3;
    transition: all 0.4s ease 0s;
    background: white;
    z-index: 102;

    &.open {
        transition: all 0.4s ease 0s;
        bottom: 0;
        left: 0;
        transform: translateY(0);
    }
    ::-webkit-scrollbar {
        width: 1;
        height: 0;
    }

    .image-header {
        position: relative;
        width: 100%;
        height: 25%;

        .gift-card-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .back-arrow {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 16px;
        }
    }

    .gift-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1em;
        width: 100%;
        height: 100%;
        box-shadow: 0px -5px 10px 0px #0000001a;
        padding: 7em 2em 4em 2em;
        background: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow-y: scroll;

        .close-zone{
            width: 95%;
            height: 50px;
            overflow: hidden;
            position: absolute;
            top: 10px;
            left: 50%;
            background: white;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .gift-name.giga{
                font-size: 24px;
                font-family: 'geomanistmedium';
                font-weight: bold;
            }
        }
        .gift-info-container {
            display: flex;
            flex-direction: column;

            .gift-info-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1em;
                align-items: flex-start;

                .gift-img {
                    width: 100%;
                    height: 200px;
                    object-fit: contain;
                    border: 1px solid lightgrey;
                    border-radius: 14px;
                }

                .gift-info {
                    display: flex;
                    flex-direction: column;
                    font-size: $font-size-medium;
                    font-weight: bold;

                    .gift-name {
                        margin-bottom: 5px;
                    }

                    .gift-price {
                        font-size: $font-size-normal;
                        font-weight: bold;
                        color: $green;
                    }

                    .gift-desc {
                        margin: 1em 0;
                        font-size: $font-size-normal;
                        font-family: 'geomanistregular';
                        font-weight: normal;
                    }
                }

            }
        }
    }
}
